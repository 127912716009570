<template>
  <section :class="$style.union_team_list">
    <div :class="$style.union_team_list_content">
      <app-prev-button @click="$router.go(-1)" />
      <div :class="$style.list_table_wrap">
        <div v-if="isLoading" :class="$style.loading">
          <base-icon name="spinner" size="24px" spin />
        </div>
        <div v-else>
          <div :class="$style.list_table_header">
            <h1 :class="$style.list_table_header_title">チーム一覧</h1>
            <div :class="$style.list_table_header_total">
              <span>{{totalRowCount}}</span>&nbsp;{{totalRowCount != null ? '件' : ''}}
            </div>
          </div>
          <div :class="$style.list_table">
            <app-table-list
              :col-defs="tableColDefs"
              :row-data="tableRowData"
              :page="tablePageIndex"

              :no-sort="true"
              :fetch-mode="true"
              :page-data-num="perPage"
              :total-record="total"
              :no-margin-b="true"
              :selectable="false"

              @page-no-click="paginate"
            >
            </app-table-list>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import AppTableList from '@/components/app/AppTableList/AppTableList'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
export default {
  name: 'UnionTeamList',
  components: { AppPrevButton, AppTableList, BaseIcon },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'チーム一覧',
      showSetting: false,
      back: '/',
      headerColor: 'white',
      bgColor: 'gray'
    })
    const unionCode = this.$route.params.union_code;
    this.fetchTeamList(unionCode);
  },
  data() {
    return {
      tableColDefs: [
        { key: 'team_id', label: 'ID' },
        { key: 'team_code', label: '認証基盤の組織コード' },
        { key: 'team_name', label: '組織名' },
        { key: 'storage_used', label: 'ストレージ使用量' },
        { key: 'team_member_count', label: 'チーム人数' },
      ],
      tableRowData: [],
      tablePageIndex: 1,
      total: null,
      perPage: 10,
      isLoading: true,
    }
  },
  methods: {
    async fetchTeamList(unionCode) {
      this.isLoading = true;
      try {
        const result = await this.$axios.get(`/api/${this.$constants.API_VERSION}/union/${unionCode}/team`, {
          params: {
            page: this.tablePageIndex
          },
        });

        if (result.data && result.data.teams) {
          this.tableRowData = result.data.teams;
          this.tablePageIndex = result.data.pagination.current_page;
          this.total = result.data.pagination.total;
        } else {
          this.tableRowData = [];
          this.total = 0;
        }
      } catch (error) {
        console.error('データ取得中にエラーが発生しました:', error);
        this.tableRowData = [];
        this.total = 0;
      } finally {
        this.isLoading = false;
      }
    },
    paginate(listPage) {
      this.tablePageIndex = Number(listPage);
      this.fetchTeamList(this.$route.params.union_code);
    },
  },
  computed: {
    totalRowCount(){
      return this.total == null ? '' : this.total;
    },
  },
}
</script>

<style lang="scss" module>
.union_team_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
}
.union_team_list_content {
  width: 100%;
}

.list_table_wrap {
  width: 100%;
  .list_table_header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 26px;

    &_title {
      font-size: 30px;
      margin: 0 20px 0 0;
    }

    &_total {
      margin-right: auto;
      font-size: 16px;
      span {
        font-weight: bold;
      }
    }

    & > *:last-child {
      margin-left: 20px;
    }
  }
  .list_table th, .list_table td {
    min-width: 150px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
</style>