<template>
  <article :class="$style.article_wrapper">
    <div :class="$style.wrapper">
      <div :class="$style.content_header">
        <div :class="$style.content_header_pc">
          <div :class="$style.avatar_wrapper">
            <base-avatar
              size="60px"
              :border-color="color"
              :bordered="!!color"
              :class="$style.avatar"
              :path="userImagePath"
            />
          </div>
          <div :class="$style.info_wrapper">
            <div :class="$style.name">{{ name }}</div>
            <div :class="$style.content_data" v-show="showComment">
              <span :class="$style.text">{{ dateString }}</span>
              <div :class="$style.likes">
                <span :class="$style.text">
                  <span :class="$style.count">{{ goodCount }}</span>
                  {{ goodCount > 1 ? 'likes' : 'like' }}
                </span>
              </div>
              <div :class="$style.edit">
                <div>
                  <span :class="$style.text" @click="commentEdit" v-if="editableFlg">
                    編集
                  </span>
                  <span :class="$style.text" v-if="editableFlg"> / </span>
                  <span
                    :class="$style.text"
                    @click="showCommentModal = true"
                    v-if="deletableFlg"
                  >
                    削除
                  </span>
                </div>
              </div>
              <a
                v-if="
                  (fileType(pathList) == 'image' || fileType(pathList) == 'file') &&
                  showComment
                "
                :class="$style.picture"
                href="#"
                @click.prevent.stop="fileDownload(pathList[0].path)"
              >
                ダウンロードする
              </a>
              <div :class="$style.menu" v-show="showEllipsis">
                <base-overlay-menu-list v-if="!isBeforeApproval">
                  <base-overlay-menu-list-item
                    to=""
                    @click.native="handleReport"
                    v-if="showReport"
                  >
                    {{
                      report_flg
                        ? '※このユーザは通報済みです'
                        : 'このユーザを通報する'
                    }}
                  </base-overlay-menu-list-item>
                  <base-overlay-menu-list-item
                    @click.native="handleBlock"
                    to=""
                    v-if="showBlock"
                    >{{
                      blockUser
                        ? 'このユーザのブロックを解除する'
                        : 'ユーザをブロックする'
                    }}</base-overlay-menu-list-item
                  >
                  <base-overlay-menu-list-item
                  to=""
                  @click.native="handleSpam"
                  v-if="showSpam"
                >
                  {{
                    spam_flg
                      ? '※このコメントは通報済みです'
                      : 'このコメントを通報する'
                  }}
                </base-overlay-menu-list-item>
                </base-overlay-menu-list>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.content_wrapper" :style="customStyle">
        <div :class="$style.content_header_sp">
          <base-avatar
              size="18px"
              :border-color="color"
              :bordered="!!color"
              :class="$style.avatar"
              :path="userImagePath"
          />
          <div :class="$style.content_data_wrapper">
            <span :class="$style.name">{{ name }}<br /></span>
            <div :class="$style.content_data" v-show="showComment">
              <span :class="$style.text">{{ dateString }}</span>
              <div :class="$style.likes">
                <span :class="$style.text">
                  <span :class="$style.count">{{ goodCount }}</span>
                  {{ goodCount > 1 ? 'likes' : 'like' }}
                </span>
              </div>
              <a :class="$style.edit">
                <div>
                  <span :class="$style.text" @click="commentEdit" v-if="editableFlg">
                    編集
                  </span>
                  <span :class="$style.text" v-if="editableFlg"> / </span>
                  <span
                    :class="$style.text"
                    @click="showCommentModal = true"
                    v-if="deletableFlg"
                  >
                    削除
                  </span>
                </div>
              </a>
              <a
                v-if="
                  (fileType(pathList) == 'image' || fileType(pathList) == 'file') &&
                  showComment
                "
                :class="$style.picture"
                href="#"
                @click.prevent.stop="fileDownload(pathList[0].path)"
              >
                ダウンロードする
              </a>
              <div :class="$style.menu" v-show="showEllipsis">
                <base-overlay-menu-list>
                  <base-overlay-menu-list-item
                    to=""
                    @click.native="handleReport"
                    v-if="showReport"
                  >
                    {{
                      report_flg
                        ? '※このユーザは通報済みです'
                        : 'このユーザを通報する'
                    }}
                  </base-overlay-menu-list-item>
                  <base-overlay-menu-list-item
                    @click.native="handleBlock"
                    to=""
                    v-if="showBlock"
                    >{{
                      blockUser
                        ? 'このユーザのブロックを解除する'
                        : 'ユーザをブロックする'
                    }}</base-overlay-menu-list-item
                  >
                  <base-overlay-menu-list-item
                  to=""
                  @click.native="handleSpam"
                  v-if="showSpam"
                >
                  {{
                    spam_flg
                      ? '※このコメントは通報済みです'
                      : 'このコメントを通報する'
                  }}
                </base-overlay-menu-list-item>
                </base-overlay-menu-list>
              </div>
            </div>
          </div>
        </div>
        <p :class="$style.content_body" v-show="showComment">
          <span v-if="type == 1" :class="$style.body" v-html="$sanitize(body)">
          </span>
          <span v-else-if="type == 2 || type == 4">
            <span v-if="pathList" :class="$style.picture">
              <span v-if="fileType(pathList) == 'image'">
                <img :src="fileUrl(pathList)" @click="imageClick" alt="" />
              </span>
              <span v-else-if="fileType(pathList) == 'file'" :class="$style.file">
                <button :class="$style.file_download" style="cursor: pointer" @click.prevent.stop="fileDownload(pathList[0].path)">
                  <img
                    :class="$style.file_icon"
                    src="@/assets/images/file_download.svg"
                    alt="icon"
                  />
                  {{ filePath(pathList) }}
                </button>
              </span>
              <span v-else-if="fileType(pathList) == 'audio_file'">
                <audio :src="fileUrl(pathList)" controls="" style="width: 260px">
                  非対応
                </audio>
              </span>
            </span>
          </span>
          <span v-else-if="type == 3" :class="$style.stamp">
            <img :src="stampUrl" alt="" />
          </span>
        </p>
        <a
          :class="$style.reply_button"
          @click="openReplyModal()"
        >
          返信
        </a>
        <div v-if="switchComment" :style="link_styles">
          ブロックしたユーザーからの投稿です<br />投稿を表示しますか？
          <a href="#" @click.prevent.stop="switchCommentView()">Yes</a>
        </div>
      </div>
      <div :class="$style.icon_wrapper" @click="like" v-if="!isBeforeApproval">
        <base-icon
          v-if="showFavorite"
          size="22px"
          :type="goodFlg ? 'fas' : 'far'"
          name="heart"
          :color="goodFlg ? 'pink' : 'gray'"
          :class="[$style.icon, $style.like]"
        />
      </div>
      <base-modal
        v-if="showCommentModal"
        @close="showCommentModal = false"
        height="130px"
        type="round"
      >
        <template v-slot:header-title>コメントを削除しますか？</template>
        <template v-slot:body>
          <div :class="$style.stamp_tab_wrap">
            <comment-tab @commentDelete="commentDelete" />
          </div>
        </template>
      </base-modal>
      <base-modal
        v-if="showImage"
        type="round"
        imageEnlargementFlg
        @close="showImage = false"
      >
        <template v-slot:body>
          <div :class="$style.modal_content">
            <img :src="fileUrl(pathList)" :class="$style.modal_image" alt="" />
          </div>
        </template>
      </base-modal>
      <app-reply-modal
        v-if="showReplyModal"
        @send="handleSendReply(true)"
        @close="handleCloseReplyModal"
        @download="fileDownload"
        :type="type"
        :body="body"
        :userImagePath="userImagePath"
        :pathList="pathList"
        :stampUrl="stampUrl"
        :name="name"
        :mentionId="userId"
        :dateString="dateString"
        :commentId="commentId"
        :chatId="chatId"
        :to_personal_options="to_personal_options_reply"
        :to_personal_loading="to_personal_loading"
        :savedCommentId="savedCommentId"
        :savedText="savedReplyText"
      />
    </div>
    <div
      v-if="newReplyCount"
      @click="openReplyList(false)"
      :class="$style.reply_toggle">
      <span v-if="isOpen" :class="$style.open_arrow">▲</span>
      <span v-else :class="$style.close_arrow">▼</span>
      <a
        :class="$style.toggle_text">
        {{ newReplyCount }}件の返信
      </a>
    </div>
    <div v-if="isOpen" :class="$style.comment_replies">
      <div v-for="reply of replies" :key="reply.comment_reply_id" :id="'reply-' + reply.comment_reply_id" ref="replyMarker">
        <app-comment-reply-list-item
          @send="handleSendReply(false)"
          @edit="handleEditReply"
          :class="$style.reply_item"
          :replyId="reply.comment_reply_id"
          :commentId="reply.comment_id"
          :chatId="reply.chat_id"
          :userId="reply.user_id"
          :name="reply.user_name"
          :type="reply.type"
          :targetText="reply.reply_target_formatted_text_web"
          :targetStamp="reply.reply_target_stamp_url"
          :targetFile="reply.reply_target_path_list"
          :targetType="reply.reply_target_type"
          :commentBody="body"
          :commentPathList="pathList"
          :commentStampUrl="stampUrl"
          :body="addMentionedStyle(reply)"
          :goodCount="reply.good_count"
          :goodFlg="reply.good_flg == 1 ? true : false"
          :editableFlg="reply.editable_flg == 1 ? true : false"
          :deletableFlg="reply.deletable_flg == 1 ? true : false"
          @like="(comment_reply_id, good_flg) => addGood(comment_reply_id, good_flg, reply)"
          @deleteReply="deleteReply"
          :stampUrl="reply.block_user_flg ? null : reply.stamp_url"
          :dateString="reply.block_user_flg ? '' : reply.created_at"
          :pathList="reply.block_user_flg ? null : reply.path_list"
          :body-color="reply.block_user_flg ? '#DDDDDD' : '#FFFFFF'"
          :userImagePath="
            reply.block_user_flg
              ? require('@/assets/images/block_chat_user.png')
              : reply.user_image_path
          "
          :showComment="!reply.block_user_flg"
          :showFavorite="!reply.block_user_flg"
          :switchReply="!!reply.block_user_flg"
          :showBlock="$store.state.auth.user_id != reply.created_by"
          :report_flg="reply.is_user_reported"
          :to_personal_options="to_personal_options_reply"
          :to_personal_loading="to_personal_loading"
          :showReport="!isUsersOwnAccount(reply)"
          @report="reportUser(reply)"
          :blockUser="reply.temp_block_user_flg"
          @blockUser="blockReplyUser(reply, 'reply')"
          @switchReplyView="switchReplyView(reply)"
          :spam_flg="reply.spam_flg"
          @spam="spamCommentReply(reply)"
        />
      </div>
      <div v-if="newReplyCount > LIMIT && moreFetchable" :class="$style.more_reply">
        <base-button
          bordered
          rounded="rounded"
          width="150px"
          @click="fetchMoreReplies"
          >
          もっと見る
        </base-button>
      </div>
    </div>
  </article>
</template>

<script>
import BaseAvatar from '../../base/BaseAvatar/BaseAvatar.vue'
import BaseIcon from '../../base/BaseIcon/BaseIcon.vue'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import CommentTab from '../../base/BaseInputComment/CommentTab.vue'
import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuList'
import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItem'
import AppReplyModal from './AppReplyModal.vue'
import AppCommentReplyListItem from './AppCommentReplyListItem.vue'
import {fetchCommentReplyList} from '@/helper/common.js'

export default {
  components: {
    BaseOverlayMenuListItem,
    BaseOverlayMenuList,
    BaseAvatar,
    BaseIcon,
    BaseModal,
    BaseButton,
    CommentTab,
    AppReplyModal,
    AppCommentReplyListItem,
  },
  name: 'AppCommentListItem',
  props: {
    authJuid: {
      type: String,
    },
    authId: {
      type: [String, Number],
    },
    /** ユーザー画像パス。BaseAvatarに準じる */
    userImagePath: {
      type: String,
    },
    /** アバター色 */
    color: {
      type: String,
    },
    /** コメントID */
    commentId: {
      type: Number,
    },
    chatId: {
      type: Number,
    },
    /** 投稿者名前 */
    name: {
      type: String,
    },
    userId: {
      type: String,
    },
    /** タイプ */
    type: {
      type: Number,
    },
    /** 本文 */
    body: {
      type: String,
      default: undefined,
    },
    /** 画像 */
    image: {
      type: String,
      default: '',
    },
    /** 添付ファイル  */
    pathList: {
      type: Array,
      default: null,
    },
    // /** 音声。スロットを使うと無視されます。 */
    // audio: {// TODO: 要音声用コンポーネント。未実装
    //   type: String,
    //   default: ""
    // },
    /** スタンプURL */
    stampUrl: {
      type: String,
      default: null,
    },
    /** 投稿日 */
    time: {
      type: [String, Date],
      default: '2w', // テスト用の値。あとで消す
    },
    dateString: {
      type: String,
      default: '',
    },
    /** like数 */
    goodCount: {
      type: Number,
      default: 0,
    },
    /** likeかどうか */
    goodFlg: {
      type: Boolean,
      default: false,
    },
    /** 編集可能かどうか */
    editableFlg: {
      type: Boolean,
      default: false,
    },
    /** 削除可能かどうか */
    deletableFlg: {
      type: Boolean,
      default: false,
    },
    /** 画面表示時のリプライカウント数 */
    replyCount: {
      type: Number,
      default: 0,
    },
    /** 画像拡大機能かどうか */
    imageEnlargementFlg: {
      type: Boolean,
      default: false,
    },
    /** 投稿日からの経過時間を計算する現在時刻 */
    currentTime: {
      type: [String, Date],
      default: () => new Date(),
    },
    blockUser: {
      type: Boolean,
      default: false,
    },
    showBlock: {
      type: Boolean,
      default: true,
    },
    showEllipsis: {
      type: Boolean,
      default: true,
    },
    showFavorite: {
      type: Boolean,
      default: true,
    },
    showComment: {
      type: Boolean,
      default: true,
    },
    switchComment: {
      type: Boolean,
      default: false,
    },
    bodyColor: {
      type: String,
      default: '#FFFFFF',
    },
    showReport: {
      type: Boolean,
      default: true,
    },
    report_flg: {
      type: Boolean,
      default: false,
    },
    showSpam: {
      type: Boolean,
      default: true,
    },
    spam_flg: {
      type: Boolean,
      default: false,
    },
    isBeforeApproval: {
      type: Boolean,
      default: false
    },
    to_personal_options: {
      type: Array,
    },
    to_personal_loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      processing: false,
      showCommentModal: false,
      showImage: false,
      showReplyModal: false,
      savedReplyText: '',
      savedCommentId: null,
      replies: [],
      newReplyCount: this.replyCount,
      clickCount: 0,
      isOpen: false,
      moreFetchable: false,
      link_styles: {
        color: 'gray',
        fontSize: '9pt',
        textAlign: 'center',
        width: '287px',
      },
      LIMIT: 20,
      to_personal_options_reply: [],
    }
  },
  watch: {
    '$route.hash'() {
      this.scrollToHash()
    }
  },
  created() {
    this.scrollToHash();
  },
  methods: {
    scrollToHash() {
      const hash = this.$route.hash
      if (hash.startsWith('#reply-')) {
        this.$nextTick(() => {
          this.openReplyList(true)
        });
      }
    },
    handleSendReply(isToComment) {
      if (isToComment) {
        this.handleCloseReplyModal('', null)
      }
      this.fetchReplyList(true, false, true)
    },
    handleEditReply() {
      this.fetchReplyList(true)
    },
    handleCloseReplyModal(replyText, replyCommentId) {
      this.showReplyModal = false
      this.savedReplyText = replyText
      this.savedCommentId = replyCommentId
    },
    handleBlock() {
      this.$emit('blockUser', this.commentId)
    },
    switchCommentView() {
      this.$emit('switchCommentView', this.commentId)
    },
    async fetchMoreReplies() {
      if (this.processing) return
      this.clickCount++
      const res = await this.fetchReplyList(false, true)
      if (res < 20) {
        this.moreFetchable = false
      }
    },
    handleReport() {
      // ユーザ通報イベント
      this.$emit('report', this.item)
    },
    handleSpam() {
      //スパム通報イベント
      this.$emit('spam', this.item)
    },
    like() {
      this.$emit('like', this.commentId, this.goodFlg)
    },
    commentEdit() {
      this.$emit('editComment', this.commentId, this.body)
    },
    commentDelete() {
      this.$emit('deleteComment', this.commentId)
      this.showCommentModal = false
    },
    openReplyList(isFromNotice) {
      if (this.processing) return
      if (!this.isOpen && !this.replies.length >0) {
        if (!isFromNotice) {
          this.fetchReplyList(false)
        } else {
          this.fetchReplyList(true)
        }
      }
      this.isOpen = !this.isOpen
    },
    async fetchReplyList(isScroll, isFetchMore = false, isAdding = false) {
      this.processing = true
      const params = {
        comment_id: this.commentId,
        sort: 'created_at',
        limit: isScroll ? null : this.LIMIT,
        offset: isScroll ? null : this.offset,
      }
      const res = await fetchCommentReplyList(params)
      if (res.status == 'success') {
        if (isFetchMore) {
          this.replies.push(...res.value.replies)
        } else {
          this.replies = res.value.replies
        }
        if (this.newReplyCount === 0) {
          this.isOpen = !this.isOpen
        }

        var tempReplies = []
        this.replies.forEach((elem) => {
          elem['temp_block_user_flg'] = elem.block_user_flg // ブロックユーザーの表示用
          tempReplies.push(elem)
        })
        this.replies = tempReplies
        if (isScroll) {
          if (isAdding) {
            this.newReplyCount++
          }
          if (!this.isOpen) {
            this.isOpen = !this.isOpen
          }
          const latestReply = this.replies[this.replies.length - 1]
          if (latestReply) {
            setTimeout(() => {this.scrollToLatestReply(latestReply['comment_reply_id'])}, 50)
          }
        } else {
          this.moreFetchable = true
        }
      } else {
        this.replies = []
        this.newReplyCount = 0
      }
      this.processing = false
      return res.value.replies.length
    },
    fileDownload: async function (path) {
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/file/download', {
          path: path,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            let base64 = res.data.value.image
            let bin = atob(base64.replace(/^.*,/, ''))
            let buffer = new Uint8Array(bin.length)
            for (var i = 0; i < bin.length; i++) {
              buffer[i] = bin.charCodeAt(i)
            }

            let blob = new Blob([buffer.buffer])
            let a = document.createElement('a')
            a.href = window.URL.createObjectURL(blob)
            a.download = res.data.value.file_name
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        })
    },
    imageClick() {
      this.showImage = true
    },
    fileType: function (path_list) {
      if (!path_list || path_list.length == 0) return ''

      return path_list[0].file_type
    },
    filePath: function (path_list) {
      if (!path_list || path_list.length == 0) return ''

      return path_list[0].path.substring(path_list[0].path.lastIndexOf('/') + 1)
    },
    fileUrl: function (path_list) {
      if (!path_list || path_list.length == 0) return ''

      return path_list[0].file_url
    },
    addGood: function (comment_reply_id, good_flg, reply) {
      if (this.processing) return

      this.processing = true
      let param_good_flg = 1
      if (good_flg && good_flg == true) {
        param_good_flg = 0
      }
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/team/chat/comment/reply/user', {
          comment_reply_id: comment_reply_id,
          read_flg: 1,
          good_flg: param_good_flg,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.replies.find((v) => v.comment_reply_id === comment_reply_id).good_flg = param_good_flg
            if (param_good_flg) {
              console.log(param_good_flg)
              reply.good_count++
            } else {
              reply.good_count--
            }
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    deleteReply: function (comment_reply_id) {
      this.$axios
        .delete('/api/' + this.$constants.API_VERSION + '/team/chat/comment/reply/delete', {
          params: {
            comment_reply_id: comment_reply_id,
          },
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.fetchReplyList(true)
            this.newReplyCount = this.newReplyCount - res.data.count
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    isUsersOwnAccount(obj) {
      return this.$store.state.auth.user_id == obj.created_by ? true : false
    },
    spamCommentReply: async function (reply) {
      if (this.processing) {
        return
      }
      if (reply.spam_flg) {
        //３点リーダーを閉じる
      } else {
        if (!confirm('このコメントを悪質なコメントとして報告しますか？')) {
          return
        }
        this.processing = true
        await this.reportSpamComment(reply.comment_reply_id)
      }
      this.processing = false
    },
    async reportSpamComment(comment_reply_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/team/chat/comment/reply/spam', {
          comment_reply_id: comment_reply_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.fetchReplyList(true)
            alert('管理者が内容を確認し、24時間以内に対応を致します。')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    switchReplyView(reply) {
      if (this.processing) {
        return
      }
      this.replies.find(
        (v) => v.comment_reply_id === reply.comment_reply_id
      ).block_user_flg = !reply.block_user_flg
    },
    reportUser: async function (reply) {
      if (this.processing) {
        return
      }
      if (reply.is_user_reported) {
        //３点リーダーを閉じる
      } else {
        if (!confirm('このユーザを悪質なユーザとして報告しますか？')) {
          return
        }
        this.processing = true
        await this.callReportUser(reply.created_by)
      }
      this.processing = false
      return
    },
    callReportUser: async function (user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/report', {
          reported_user_id: user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.fetchReplyList(true)
            alert('管理者が内容を確認し、24時間以内に対応を致します。')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    blockReplyUser: async function (reply) {
      if (this.processing) {
        return
      }
      this.processing = true
      if (reply.temp_block_user_flg) {
        if (!confirm('ユーザのブロックを解除しますか？')) {
          this.processing = false
          return
        }
        await this.deleteBlockUser(reply.created_by)
      } else {
        if (!confirm('ユーザをブロックしますか？')) {
          this.processing = false
          return
        }
        await this.updateBlockUser(reply.created_by)
      }
      this.processing = false
    },
    async updateBlockUser(block_user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/block/registration', {
          block_user_id: block_user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.fetchReplyList(true)
            alert('ユーザをブロックしました')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    async deleteBlockUser(block_user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/block/delete', {
          block_user_id: block_user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.fetchReplyList(true)
            alert('ユーザのブロックを解除しました')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    /**
     * 自分宛のメンションの場合太字と黄色背景のタグを付与する
     */
    addMentionedStyle(body){

      if(body.mentions.length <= 0){
        // メンション対象が設定されていない場合はreturn
        return body.formatted_text_web;
      }
      // eslint-disable-next-line
      const regex = new RegExp(`(<mention juid=.*?>)(.*?)(<\/mention>)`);
      const is_exist = regex.test(body.formatted_text_web)
      if(is_exist){
        // mentionタグの改修実装後は、juid属性で比較
        const searchTerm = this.authJuid
        // 正規表現を使用してmentionタグにスタイリングを適用
        // eslint-disable-next-line
        const regex = new RegExp(`(<mention juid="${searchTerm}".*?>)(.*?)(<\/mention>)`, 'g');
        const styledHtml = body.formatted_text_web.replace(regex, `<span style="font-weight: bold; background: #f2c74466;">$1$2$3</span>`);
        return styledHtml
      }

      // mentionタグ実行前の対応
      for(let i = 0; i < body.mentions.length; i++){
        if(body.mentions[i].user_id === this.authId){
          const re = new RegExp('@' + body.user_name, 'g')
          const replacedText = body.formatted_text_web
          const mentionedText = replacedText.replace(re, function(match) {
            return `<span style="font-weight: bold; background: #f2c74466;">${match}</span>`;
          })
          return mentionedText
        }else{
          return body.formatted_text_web
        }
      }
    },
    scrollToLatestReply(replyId) {
      this.$nextTick(() => {
        const lastElement = document.getElementById('reply-'+replyId)
        if (lastElement) {
          const elementHeight = lastElement.clientHeight
          const viewportHeight = window.innerHeight
          const scrollPosition = lastElement.offsetTop - (viewportHeight - elementHeight) / 2
          window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
          })
        }
      })
    },
    fetchReplyMentionList: function () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/chat/mention/list', {
          params: { chat_id: this.$route.params.chat_id , comment_id: this.commentId }
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.to_personal_options_reply = []
            const userList = res.data.value?.mention_user_list
            if (userList) {
              userList.forEach((user) => {
                this.to_personal_options_reply.push({
                  id: user.user_id,
                  juid: user.juid,
                  nickname: user.nickname,
                  label: user.nickname
                    ? user.nickname
                    : user.name
                    ? user.name
                    : user.juid,
                  choicesLabel: user.nickname_juid,
                  profile_image_path: user.profile_image_path
                });
              });
            }
            // this.to_personal_loading = false;
            console.log(res.data.value?.mention_user_list)
            this.users = res.data.value.mention_user_list.map(user => {
              return {
                user_id: user.user_id,
                value: user.nickname,
              }
            })
            this.users_count = this.users.length;
          } else {
            this.users = []
          }
        })
    },
    async openReplyModal() {
      await this.fetchReplyMentionList()
      this.showReplyModal = true
    },
  },
  computed: {
    offset() {
      return this.LIMIT * this.clickCount
    },
    customStyle() {
      return {
        'background-color': `${this.bodyColor}`,
      }
    },
    currentTimeDate() {
      return this.currentTime instanceof Date
        ? this.currentTime
        : new Date(this.currentTime)
    },
    timeDate() {
      return this.time instanceof Date ? this.time : new Date(this.time)
    },
    timeDateIsValid() {
      return (
        !Number.isNaN(this.timeDate.getTime()) &&
        !Number.isNaN(this.currentTimeDate.getTime())
      )
    },
    timeAttr() {
      return this.timeDateIsValid ? this.timeDate.toISOString() : this.time
    },
    timeText() {
      if (!this.timeDateIsValid) {
        return this.time
      }
      const ms1sec = 1000
      const ms1min = 60 * ms1sec
      const ms1hour = 60 * ms1min
      const ms1day = 24 * ms1hour
      const ms1week = 7 * ms1day
      const ms1month = 30 * ms1day
      const ms1year = 365 * ms1day
      const diff = this.currentTimeDate - this.timeDate
      const year = Math.floor(diff / ms1year)
      if (year > 0) {
        return `${year}y`
      }
      const month = Math.floor(diff / ms1month)
      if (month > 0) {
        return `${month}m`
      }
      const week = Math.floor(diff / ms1week)
      if (week > 0) {
        return `${week}w`
      }
      const day = Math.floor(diff / ms1day)
      if (day > 0) {
        return `${day}d`
      }
      const hour = Math.floor(diff / ms1hour)
      if (hour > 0) {
        return `${hour}h`
      }
      const min = Math.floor(diff / ms1min)
      if (min > 0) {
        return `${min}m`
      }
      const sec = Math.floor(diff / ms1sec)
      if (sec > 10) {
        return `${sec}m`
      }
      return `now`
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;

  &:not(:last-child) {
    //margin-bottom: 20px;
  }

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  .avatar_wrapper {
    .avatar {
    }
  }

  .content_wrapper {
    flex: 1;
    padding: 5px;
    .content_body {
      font-size: 15px;
      line-height: 1.7;
      color: $keyBlack;
      margin: 0;
      margin-bottom: 2px;

      & > *:not(:last-child) {
        margin-right: 14px;
      }
      .name {
        font-weight: bolder;
        vertical-align: top;
      }
      .body {
        display: block;
        // white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-word;
      }
      .body img {
        max-width: 100%;
        height: auto;
      }
      .image {
      }
      .block_link {
        display: block;
        align-items: center;
        justify-content: center;
        font-size: 10px;
      }
    }
    .content_image {
      margin: 0 auto;
      max-width: 200px;
      max-height: 300px;
      img {
        max-width: 200px;
        max-height: 300px;
      }
    }
    .content_data {
      line-height: 10px;
      color: #b7b7b7;

      & > * {
        display: inline-block;
        min-width: 20px;
        height: 10px;
      }
      & > *:not(:last-child) {
        margin-right: 10px;
      }

      .text {
        display: inline-block;
        font-size: 10px;
      }

      .time {
      }
      .likes {
        .count {
          margin-right: 2px;
        }
      }
      .edit {
        user-select: none;
        cursor: pointer;
      }
      .picture {
        color: $KeyDarkGreen;
        font-size: 10px;
        padding: 3px 0 0 0;
        width: 80px;
        float: right;
      }
      .menu {
        width: 18px;
        height: 18px;
        display: inline-block;
        align-items: center;
        justify-content: center;
        margin: 5px;
      }
    }
    .reply_button {
      font-size: 18px;
      color: #4AC0C0;
      cursor: pointer;
      text-decoration: underline;
      @include mobile {
        font-size: 14px;
      }
    }
  }

  .icon_wrapper {
    .icon {
      user-select: none;
      cursor: pointer;
    }

    .like {
    }
  }

  .picture {
    padding: 0;
    vertical-align: top;
    display:block;

    img {
      max-width: 200px;
      max-height: 300px;
    }

    .file {
      width: 100%;
      justify-content: center;

      .file_download {
        width: 160px;
        border: 1px solid #707070;
        background-color: $keyWhite;
        padding: 4px 4px;
        color: #000000;
        text-decoration: none;
        text-align: left;

        .file_icon {
          display: inline-block;
          padding-right: 6px;
          width: 16px;
          height: 20px;
          vertical-align: middle;
        }
      }

      input {
        display: none;
      }
    }
  }

  .stamp {
    padding: 0;
    vertical-align: top;
    display:block;
  }
}

.reply_toggle {
  margin-left: 85px;
  a {
    color: #4AC0C0;
  }
  .open_arrow {
    color: #4AC0C0;
    display: inline-block;
    transform: scale(0.5);
    transform-origin: center;
  }
  .close_arrow {
    color: #4AC0C0;
    display: inline-block;
    transform: scale(0.5);
    transform-origin: center;
  }
  .toggle_text {
    text-decoration: underline;
    cursor: pointer;
  }
}

.comment_replies {
  margin-left: 75px;
  .reply_item {
    padding: 8px 0 8px;
  }
  .more_reply {
    margin: 20px 0 10px;
    display: flex;
    justify-content: center;
  }
}

.modal_content {
  margin: 16px auto;
  width: 80%;
  @include mobile() {
    width: auto;
    margin: 16px;
  }
  &_description {
    font-size: 11px;
  }
  &_name {
    font-size: 16px;
    margin-left: 16px;
  }
  h2 {
    font-size: 14px;
  }
  section:first-of-type {
    margin-bottom: 56px;
  }
}
.modal_image {
  width: 100%;
}


// phase2.3
.content_header{
  &_pc{
    display: flex;
    align-items: center;
    width: 265px;
    gap: 0 25px;

    .info_wrapper{
      font-size: 13px;
      .name{
        font-weight: bold;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
    .content_data{
      font-size: 12px;
      color: rgb(96,96,96);
      display: flex;
      flex-wrap: wrap;
      .likes{
        .text{
          margin: 0 10px;
        }
      }
      .menu{
        margin: 0 0 0 10px;
      }

    }
  }
  &_sp{
    display: none;
  }
}
//投稿/ミニ投票詳細画面で「編集/削除」のマウスオーバーを指アイコンに修正
.edit {
        user-select: none;
        cursor: pointer;
      }

.wrapper {
  .content_wrapper {
    .content_body {
      .name {
        display: none;
      }
    }
    .content_data{
      display: none;
    }
    p {
      margin-block: 0 0;
    }
  }

  .picture{
    color: #4AC0C0;
  }
}

@include mobile_only() {
  .content_header{
    &_pc{
      display: none;
    }
    &_sp{
      display: flex;
      align-items: center;
      .avatar {
        margin-right: 5px;
      }
    }
  }
  .reply_toggle {
    margin-left: 10px;
  }
  .wrapper {
    .content_data_wrapper {
      display: flex;
      flex-direction: column;
    }
    .content_wrapper {
      .name {
        font-size: 12px;
      }
      .content_data {
        display: block;
        .picture {
          margin-top: 10px;
        }
        .menu,
        .edit,
        .likes {
          margin-left: 10px;
        }
      }
      .content_body {
        font-size: 12px;
        .name {
          display: block;
        }
        .body {
          margin: 4px 0;
        }
      }
      .reply_button {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 371px) {
  .wrapper {
    .content_wrapper {
      .content_data {
        margin-top: 0px;
      }
    }
  }
}
</style>
