<template>
  <div :class="$style.home_profile_edit_bg">
    <section :class="$style.home_profile_edit">
      <app-prev-button margin="10px 10px 0px" @click="backButtonAction(prevRoute)" />
      <div :class="$style.home_profile_edit_content">
        <base-form-field name="profile-avatar-change" no-border>
          <div :class="$style.profile_avatar_wrap">
            <div :class="$style.profile_avatar">
              <base-avatar size="56px" :path="inputProfileImage ? inputProfileImage : NoImage"/>
            </div>
            <base-file-input
              name="profile-avatar-change"
              @change="setFile"
            >
              <p :class="$style.profile_link">プロフィール画像を変更</p>
            </base-file-input>
            <p style="font-size:12px">JU-ID: {{juid}}</p>
            <base-button
              rounded="rounded"
              width="160px"
              height="30px"
              @click="clickJuidCopy(juid)"
            >JU-IDをコピーする</base-button>
          </div>
        </base-form-field>
        <base-message type="error" :class="$style.error_txt" v-if="error_message">{{error_message}}</base-message>
        <base-form-field name="name" label="名前" no-border>
          <base-input
            name="name"
            v-model="inputName"
            maxLength="255"
            :error="v$.inputName.$invalid && v$.inputName.$dirty ? v$.inputName.$errors[0].$message : isFieldErrorMsg('name', errorMsgList)"
          />
        </base-form-field>

        <base-form-field name="kana" label="ふりがな" no-border>
          <base-input
            name="kana"
            v-model="inputFurigana"
            maxLength="255"
            :error="v$.inputFurigana.$invalid && v$.inputFurigana.$dirty ? v$.inputFurigana.$errors[0].$message : isFieldErrorMsg('kana', errorMsgList)"
          />
        </base-form-field>

        <base-form-field name="view-profile">
          <base-check-box
            name="view-profile"
            v-model="inputDisplayNameFlg"
          >
            <span :class="$style.label">プロフィールに本名を表示する</span>
          </base-check-box>
        </base-form-field>

        <base-form-field name="nickname" label="ニックネーム">
          <base-input
            name="nickname"
            v-model="inputNickname"
            maxLength="255"
            :error="v$.inputNickname.$invalid && v$.inputNickname.$dirty ? v$.inputNickname.$errors[0].$message : ''"
          />
        </base-form-field>

        <base-form-field name="birthday" label="生年月日">
          <base-date-picker
            name="birthday"
            type="date"
            v-model="inputBirthday"
            :error="
              v$.inputBirthday.$invalid && v$.inputBirthday.$dirty
                ? v$.inputBirthday.$errors[0].$message
                : isFieldErrorMsg('birthday', errorMsgList)
            "
          >
          </base-date-picker>
        </base-form-field>

        <base-form-field name="profile" label="プロフィール">
          <base-text-area
            name="profile"
            v-model="inputOpenProfile"
            maxLength="1000"
            :rows="4"
            :error="v$.inputOpenProfile.$invalid && v$.inputOpenProfile.$dirty ? v$.inputOpenProfile.$errors[0].$message : ''"
          />
        </base-form-field>

        <base-form-field name="tel" label="電話番号">
          <base-input
            name="tel"
            v-model="inputTel"
            maxLength="15"
            :disabled="$store.state.auth.tel_or_email === inputTel"
            :error="v$.inputTel.$invalid && v$.inputTel.$dirty ? v$.inputTel.$errors[0].$message : isFieldErrorMsg('tel', errorMsgList)"
          />
        </base-form-field>

        <base-form-field name="mail-address" label="メールアドレス">
          <base-multi-input
            type="text"
            name="mail-address"
            v-model="inputEmailArray"
            maxLength="255"
            @appended="appendedEmail"
            @removed="removedEmail"
            @update="changeEmails"
            :error="emailError"
          />
        </base-form-field>

        <base-form-field name="mail-address" label="現在のパスワード">
          <base-input
            type="password"
            v-model="inputCurrentPassword"
            maxLength="255"
            autocomplete="new-password"
            :error="v$.inputCurrentPassword.$invalid && v$.inputCurrentPassword.$dirty ? v$.inputCurrentPassword.$errors[0].$message : isFieldErrorMsg('new_password', errorMsgList)"
          />
        </base-form-field>

        <base-form-field name="password" label="新しいパスワード">
          <base-input
            type="password"
            v-model="inputNewPassword"
            maxLength="255"
            :error="v$.inputNewPassword.$invalid && v$.inputNewPassword.$dirty ? v$.inputNewPassword.$errors[0].$message : ''"
          />
        </base-form-field>

        <base-form-field name="password" label="パスワード(確認)">
          <base-input
            type="password"
            v-model="inputNewPasswordConfirmation"
            maxLength="255"
            :error="v$.inputNewPasswordConfirmation.$invalid && v$.inputNewPasswordConfirmation.$dirty ? v$.inputNewPasswordConfirmation.$errors[0].$message : ''"
          />
        </base-form-field>
      </div>
      <div :class="$style.home_profile_edit_footer">
        <div :class="$style.confirm_button_wrap">
          <base-button
            bordered
            rounded="rounded"
            width="120px"
            @click.prevent="$router.push(`/home/home`)"
          >キャンセル</base-button>
          <div :class="$style.submit_button_area">
            <base-button
              rounded="rounded"
              width="120px"
              @click.prevent="update"
            >設定完了</base-button>
          </div>
        </div>
      </div>
    </section>
    <base-modal
      v-if="showCopied"
      type="round"
      :hidenCloseBtn="true"
      :width="'400px'"
      :disableMobileWidth="true"
    >
      <template v-slot:header-title>JU-IDをコピーしました </template>
      <template v-slot:footer>
        <base-button
          rounded="rounded"
          width="150px"
          height="30px"
          @click="showCopied = false"
          >閉じる</base-button
        >
      </template>
    </base-modal>
  </div>
</template>

<script>
import BaseAvatar from '@/components/base/BaseAvatar/BaseAvatar'
import BaseDatePicker from '@/components/base/BaseDatePicker/BaseDatePicker'
import BaseFileInput from '@/components/base/BaseFileInput/BaseFileInput'
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseCheckBox from '@/components/base/BaseCheckBox/BaseCheckBox'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseMultiInput from '@/components/base/BaseMultiInput/BaseMultiInput'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, sameAs, helpers } from '@vuelidate/validators'
import BaseFormField from '@/components/base/BaseFormField/BaseFormField'
import BaseTextArea from "../../components/base/BaseTextArea/BaseTextArea";
import {validatePassword} from "../../utils/helper";
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import {isFieldErrorMsg} from '@/helper/validator.js'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import NoImage from '@/assets/images/account-circle.svg'

// import isMobile from "@/utils/mixins/isMobile"

export default {
  name: 'HomeProfileEdit',
  // mixins: [isMobile()],
  components: {BaseTextArea, BaseAvatar, BaseFileInput, BaseInput, BaseMultiInput, BaseCheckBox, BaseButton, BaseDatePicker, BaseFormField,  BaseMessage, BaseModal, AppPrevButton},
  mounted() {
    this.emitPageUpdated()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from && from.name) {
        if (from.name == 'HomeProfile') {
          vm.prevRoute = { name: 'HomeHome'}
        } else {
          vm.prevRoute = from
        }
      } else {
        vm.prevRoute = "__history_back"
      }
    })
  },
  data() {
    return {
      emailError: "",
      newPasswordError: "",

      update_sending: false,

      inputProfileImage: "",
      inputName: "",
      inputFurigana: "",
      inputDisplayNameFlg: false,
      inputNickname: "",
      inputBirthday: "",
      inputOpenProfile: "",
      inputTel: "",
      inputEmails: [{email: ""}],
      inputEmailArray: [""],
      inputCurrentPassword: "",
      inputNewPassword: "",
      inputNewPasswordConfirmation: "",
      juid: "",

      error_message: "",
      errorMsgList: [],
      prevRoute: '',
      NoImage,
      showCopied: false,
    }
  },
  setup: function() {
    return { v$: useVuelidate() }
  },
  created: async function() {
    const user = await this.getUser()

    this.inputProfileImage = user.profile_image_path
    this.inputName = user.name
    this.inputFurigana = user.furigana
    this.inputDisplayNameFlg = Boolean(user.display_name_flg)
    this.inputNickname = user.nickname
    this.inputBirthday = this.$dayjs(user.birthday).format('YYYY-MM-DD')
    this.inputOpenProfile = user.open_profile
    this.inputTel = user.tel
    this.juid = user.juid

    if(user.emails.length > 0) {
      this.inputEmails = user.emails
      this.inputEmailArray = []

      user.emails.forEach(function (v) {
        this.inputEmailArray.push(v.email)
      }.bind(this))
    }
  },
  validations: function() {
    return {
      inputName: {
        required: helpers.withMessage('名前の入力は必須です。', required),
        $autoDirty: true
      },
      inputFurigana: {
        required: helpers.withMessage('ふりがなの入力は必須です。', required),
        $autoDirty: true
      },
      inputNickname: {
        required: helpers.withMessage('ニックネームの入力は必須です。', required),
        $autoDirty: true
      },
      inputBirthday: {
        $autoDirty: true
      },
      inputOpenProfile: {
        // required: helpers.withMessage('公開プロフィールの入力は必須です。', required),
        $autoDirty: true
      },
      inputTel: {
        // required: helpers.withMessage('電話番号の入力は必須です。', required),
        $autoDirty: true
      },
      // inputEmail: {
      //   email: helpers.withMessage('メールアドレスの形式が違います。', email),
      //   $autoDirty: true
      // },
      inputCurrentPassword: {
        requiredIfInputPassword: helpers.withMessage('パスワードの入力時に現在のパスワードの入力は必須です。', requiredIf(this.inputNewPassword || this.inputNewPasswordConfirmation)),
        // required: helpers.withMessage('パスワードの入力は必須です。', required),
        password: helpers.withMessage('パスワードには、10文字以上の英大文字・英小文字・数字・記号(^$*.[]{}()?"!@#%&/,><\':;|_~`)それぞれを最低1文字ずつ含む文字列を指定してください。', validatePassword),
        $autoDirty: true
      },
      inputNewPassword: {
        requiredIfInputPassword: helpers.withMessage('パスワードの入力時に新しいパスワードの入力は必須です。', requiredIf(this.inputCurrentPassword)),
        password: helpers.withMessage('パスワードには、10文字以上の英大文字・英小文字・数字・記号(^$*.[]{}()?"!@#%&/,><\':;|_~`)それぞれを最低1文字ずつ含む文字列を指定してください。', validatePassword),
        $autoDirty: true
      },
      inputNewPasswordConfirmation: {
        requiredIfInputPassword: helpers.withMessage('パスワードの入力時にパスワード(確認)の入力は必須です。', requiredIf(this.inputCurrentPassword)),
        sameAsInputPassword: helpers.withMessage('新しいパスワードと同じものを入力してください', sameAs(this.inputNewPassword)),
        $autoDirty: true
      }
    }
  },
  methods: {
    emitPageUpdated() {
      this.$router.app.$emit('page-updated', {
        pageTitle: 'プロフィール編集',
        showSetting: false,
        back: this.prevRoute,
        bgColor: 'white',
        headerColor: 'yellow',
        showTabbar: false,
        showFooter: true, // this.isMobile
      })
    },
    setFile([base64]) {
      this.inputProfileImage = base64
    },
    getUser: async function() {
      let user = {}

      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/user/info')
        .then((res) => {
          user = res.data.value.user
        })

      return user
    },
    update: async function() {
      this.newPasswordError = ''
      this.error_message = ''
      this.errorMsgList = []

      const result = await this.v$.$validate()

      if(! result){
        this.error_message = '入力した値を見直してください。'
        this.scrollToTop()
        return
      }
      // 同じEメールがある場合にはエラー出力する
      if(this.inputEmailArray.length !== new Set(this.inputEmailArray).size){
        this.error_message = '入力した値を見直してください。'
        this.emailError = "同じメールアドレスは登録できません。"
        this.scrollToTop()
        return
      }

      if(this.update_sending){
        return
      }
      //連投させないようにログイン中フラグを立てておく
      this.update_sending = true

      let profileImage = null
      //https:だった場合は変更してないので更新しない
      if(! this.inputProfileImage.includes('https:')){
        profileImage = this.inputProfileImage.split(',')[1]
      }

      await this.$axios.post('/api/' + this.$constants.API_VERSION + '/user/update', {
        "nickname": this.inputNickname,
        "birthday": this.inputBirthday.replaceAll('-', ''),
        "profile_image": profileImage,
        "display_name_flg": this.inputDisplayNameFlg,
        "name": this.inputName,
        "furigana": this.inputFurigana,
        "open_profile": this.inputOpenProfile,
        "tel": this.inputTel,
        "emails": this.inputEmails,
        "current_password": this.inputCurrentPassword,
        "new_password": this.inputNewPassword,
        "new_password_confirmation": this.inputNewPasswordConfirmation
      }).then(() => {
        this.$router.push('/home/profile')
      }).catch((err) => {
        this.errorMsgList = err.response.data.value.errors
        this.errorMsgList.forEach(function(v){
          if(v.attribute === "new_password" && v.message.includes("認証エラーが発生しました")){
            v.message = "現在のパスワードが間違っています。"
          }
        }.bind(this))
        this.error_message = '入力した値を見直してください。'
        this.scrollToTop()
      })

      this.update_sending = false
    },
    appendedEmail: function(v){
      this.inputEmailArray = v
      this.inputEmails.push({email: ""})
    },
    removedEmail: function(v, i){
      //authフラグがない場合のみ削除可能
      if(! this.inputEmails[i].two_step_auth_flg) {
        this.inputEmailArray = v
        this.inputEmails.splice(i, 1)
      }else{
        this.emailError = "認証に使っているメールアドレスの変更はできません。"
      }
    },
    changeEmails: function(v, i){
      if(! this.inputEmails[i].two_step_auth_flg) {
        this.inputEmails[i]['email'] = v[i]
      }else{
        //authフラグがある場合は、変更させたくないので元の値でリセットする
        this.inputEmailArray[i] = this.inputEmails[i]['email']
        this.emailError = "認証に使っているメールアドレスの変更はできません。"
      }
    },
    scrollToTop: function(){
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    },
    backButtonAction(back) {
      if (back === '__history_back') {
        this.$router.go(-1)
      } else {
        this.$router.push(back, () => {})
      }
    },
    clickJuidCopy(juid) {
      if (this.processing) return

      this.processing = true
      const elem = document.createElement('input')
      elem.value = juid
      document.body.appendChild(elem)
      elem.select()
      document.execCommand('copy')
      document.body.removeChild(elem)
      this.showCopied = true
      this.processing = false
    },
    isFieldErrorMsg
  },
  // watch: {
  //   isMobile() {
  //     this.emitPageUpdated()
  //   }
  // }
}
</script>

<style lang="scss" module>
.home_profile_edit_bg {
  width: 100%;
  background-color: $backGround;

  @include pc() {
    padding-bottom: 50px;// いる？要確認
  }
}
.home_profile_edit {
  @include pc() {
    width: 980px;
    margin: 0 auto;
    background-color: $keyWhite;
  }

  @include mobile() {
    width: 100%;
    background-color: $backGround;
    padding-bottom: 84px; // hide_tabbarでfixedな決定ボタン部分85pxで覆う場合の下部スペース
  }

  &_content {
    width: 375px;
    margin: 0 auto;
    margin-top: 12px;
    @include mobile() {
      margin-top: 10px;
    }
  }
}

.profile_avatar_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-bottom: 24px;

  // @include mobile() {
  //   margin-bottom: 24px;
  // }

  .profile_avatar {
    margin-bottom: 5px;
  }
  .profile_link {
    margin: 0;
    text-decoration: underline;
    color: $fontBlack;
    font-size: 10px;
    cursor: pointer;

    @include mobile() {
      font-size: 10px;
    }
  }
}

.name_input_group {
  width: 100%;
}

.input_wrap {
  width: 100%;
  padding: 0 32px;
  box-sizing: border-box;
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .input_caption {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.home_profile_edit_footer {
  display: block;
  position: sticky;
  width: 100%;
  height: 85px;

  @include mobile(){
    background: $keyYellow;
    box-sizing: border-box;
    border-top: 1px solid $borderLiteGray;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 12;
  }

  .confirm_button_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    & > :first-child {
      margin-right: 20px;
    }
  }
}

.submit_button_area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

$textSize_Error: 13px;
$textSpecing_Error: 4px;
$textColor_Error: #ff0000;

.error_txt {
  width: 100% !important;
  margin-top: 10px;
}
</style>
