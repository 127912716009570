<template>
  <section
    :class="[
      $style.wrapper,
      wide ? $style.wide : ''
    ]"
    :style="customStyle"
    @click="handleClick"
  >
    <picture
      :class="[
        $style.picture,
        movie ? `${$style.show_after}` : ''
      ]"
      :border-color="borderColor"
      :outline-color="outlineColor"
    >
      <img :src="image" />
    </picture>
    <div :class="$style.text_wrap">
      <div :class="$style.info_wrap">
        <p :class="$style.title_annotation">
          <time v-if="time" :datetime="timeAttr" :class="$style.time_text">
            {{ timeText }}
          </time>
          <span v-else>
            {{ titleAnnotation }}
          </span>
          <span
            v-if="showIcon"
            :class="[$style.icons_wrapper, { [$style.selected]: !!goodFlg }]"
            @click.stop="$emit('select', lectureId)"
          >
            <base-icon
              type="fas"
              name="heart"
              color="iconColor"
              size="10px"
            />
          </span>
        </p>
      </div>
      <div :class="[$style.title_wrap]">
        <p :class="$style.title">
          {{ title }}
        </p>
      </div>
      <p :class="$style.name">
        {{ name }}
      </p>
    </div>
  </section>
</template>

<script>
import BaseIcon from '../../../base/BaseIcon/BaseIcon'

export default {
  name: 'AppCarouselSearchResult',
  components: {
    BaseIcon,
  },
  data() {
    return {
      show: false,
    }
  },
  props: {
    lectureId: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: '8月の活動日決めます！投票してください', // あとで消す
    },
    name: {
      type: String,
      default: 'オタメシ労働組合湘南サーフィン支部', // あとで消す
    },
    image: {
      type: String,
      default: require('@/assets/images/no-image.png'),
    },
    movie: {
      type: Boolean,
      default: false,
    },
    time: {
      type: [String, Date, Number],
      default: () => null,
    },
    titleAnnotation: {
      type: [String],
      default: () => '',
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'heart',
      validator: (icon) => !icon || ['heart'].includes(icon),
    },
    goodFlg: {
      type: Boolean,
      default: null,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: false,
      default: null,
    },
    color: {
      type: String,
      default: '#FFFFFF',
    },
    borderColor: {
      type: String,
      default: null,
    },
    outlineColor: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleClick() {
      this.$emit('click', this.item)
    },
  },
  computed: {
    customStyle() {
      let style = {}
      return style
    },
    timeAttr() {
      const date =
        this.time instanceof Date
          ? this.time
          : new Date(this.time.replace(/-/g, '/'))
      return date.toLocaleString()
    },
    timeText() {
      if (!this.time) {
        return ''
      }
      const date =
        this.time instanceof Date
          ? this.time
          : new Date(this.time.replace(/-/g, '/'))
      return `${date.getFullYear()}/${('0' + (date.getMonth() + 1)).slice(
        -2
      )}/${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(
        -2
      )}:${('0' + date.getMinutes()).slice(-2)}`
    },
    iconColor() {
      return this.selected ? 'pink' : 'gray'
    },
  }
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: $keyWhite;
  border: solid 1px $borderLiteGray;
  border-radius: 16px;
  box-sizing: border-box;
  justify-content: space-evenly;

  @media (max-width: 767px) {
    justify-content: flex-start;
    padding: 10px 10px;
    min-height: 183px;
  }

  .picture {
    position: relative;
    display: block;
    width: 100%;
    height: 88px;
    object-fit: cover;
    flex-shrink: 1;
    border-radius: 10px;
    &.show_after::after {
      display: inline-block;
      width: 50px;
      height: 50px;
      background-image: url('../../../../assets/images/list/icon-play.svg');
      background-size: contain;
      vertical-align: middle;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      @media (max-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }
    @media (max-width: 767px) {
      height: 50px;
    }
    img {
      object-fit: cover;
      -o-object-fit: cover;
      width: 100%;
      height: 88px;
      transition: transform 0.4s ease;
      border-radius: 5px;
      @media (max-width: 767px) {
        height: 50px;
      }
    }
  }

  .title_annotation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    color: $fontGray;
    margin: 0;
    font-size: 10px;
  }
  .time_text {
    font-size: 10px;
  }

  .icons_wrapper {
    display: flex;
    cursor: pointer;
    margin-left: auto;
    &.selected {
      color: $keyPink;
    }
  }

  .title {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    -webkit-box-orient: vertical;
    color: $keyBlack;
    line-height: 1.75;
    font-size: 14px;
    font-weight: bold;
    word-break: break-all;
    overflow: hidden;
    flex-grow: 2;
    margin: -5px 0;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .title_wrap {
    margin-bottom: auto;
  }

  .name {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: $keyBlack;
    line-height: 1.5;
    margin: -2px 0;
    font-size: 10px;
    word-break: break-all;
    overflow: hidden;
  }

  .text_wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
    gap: 10px;
  }
  .info_wrap {
    display: flex;
    gap: 10px;
  }
  
  &.wide {
    flex-direction: row; 
    align-items: center;
    gap: 20px;
    padding: 32px;

    .picture {
      flex-shrink: 0;
      width: 60%;
      height: 88px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .text_wrap {
      display: flex;
      flex-direction: column;
      gap: 5px;
      height: auto;
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .info_wrap {
      margin-bottom: 5px;
    }

    .title_wrap {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .title {
      font-size: 16px;
      -webkit-line-clamp: 3;
    }
    
  }

  &:hover {
    .picture,
    .info_wrap,
    .title_wrap,
    .name {
      opacity: 0.7;
    }
    picture img {
      transform: scale(1.1);
    }
  }

}

.wrapper, .wrapper * {
  box-sizing: border-box;
}

</style>
