<template>
  <div
    :class="[
      $style.movie_layout,
    ]"
  >
    <template v-if="searchProcessing">
      <div :class="$style.movie_layout_loading"  v-if="searchProcessing">
        <base-icon name="spinner" size="32px" spin />
      </div>
    </template>
    <template v-if="!searchProcessing">
      <div :class="$style.search_wrap">
          <span :class="$style.search_label">講座・イベント検索</span>
          <base-input
            :class='$style.custom_base_input'
            type="text"
            name="search_input"
            look="outline2"
            v-model="keyWord"
            @change="fetchSearchResults()"
          >
            <template v-slot:prepend>
              <base-icon name="search" size="18px" />
            </template>
          </base-input>
      </div>

      <div
        v-if="showSearchAccordion"
        :class="[$style.accordion_section]"
      >
        <base-accordion title="検索結果" type="borderless" bold :expanded="searchResults.length !== 0">
          <div v-if="searchResults.length > 0" :class="$style.container">
            <div
              v-for="searchResult in searchResults"
              :class="$style.item_wrap"
              :key="searchResult.id"
            >
              <app-carousel-search-result
                v-if="searchResult.lecture_id !== 0"
                :lectureId="searchResult.lecture_id"
                :title="searchResult.lecture_title"
                :name="searchResult.corner_name"
                :image="searchResult.lecture_path"
                :movie=true
                :titleAnnotation="`Step1 - ${searchResult.step1_achievement_rate}% Step2 - ${searchResult.step2_achievement_rate}%`"
                :showIcon=true
                :goodFlg="!!searchResult.good_flg"
                :border-color="
                  searchResult.team_color
                    ? `rgb(${searchResult.team_color})`
                    : '#7BE293'
                "
                @click="goToLecture(searchResult.lecture_id)"
                @select="
                  goodChange(
                    searchResult.lecture_id,
                    searchResult.good_flg
                  )
                "
              />
            </div>
          </div>
          <div :class="$style.pagination_controls" v-if="searchResults.length > 0">
            <button @click="fetchSearchResults(currentPage.searchResults - 1, true)" :disabled="!hasPrev">
              <base-icon
                :class="$style.expand_arrow"
                name="chevron-left"
                size="17px"
                color="gray"
              />
            </button>
            <span :class="$style.current_page">{{ currentPage.searchResults }}</span>
            <button @click="fetchSearchResults(currentPage.searchResults + 1, true)" :disabled="!hasNext">
              <base-icon
                :class="$style.expand_arrow"
                name="chevron-right"
                size="17px"
                color="gray"
              />
            </button>
          </div>
        </base-accordion>
        <div style="margin: 10px 0;">
          <span v-if="searchResults.length == 0"
            >検索結果はありません</span
          >
        </div>
      </div>
    </template>

      <div :class="[$style.accordion_section]">
        <base-accordion title="注目の講座・イベント" type="borderless" bold expanded>
          <div :class="[$style.container]">
            <div
              v-for="(featured_lecture, index) in featuredLectures"
              :class="[$style.item_wrap, $style.highlighted]"
              :key="featured_lecture.id"
            >
              <app-carousel-search-result
                v-if="featured_lecture.lecture_id !== 0"
                :lectureId="featured_lecture.lecture_id"
                :title="featured_lecture.lecture_title"
                :name="featured_lecture.corner_name"
                :image="featured_lecture.lecture_path"
                :movie=true
                :titleAnnotation="`Step1 - ${featured_lecture.step1_achievement_rate}% Step2 - ${featured_lecture.step2_achievement_rate}%`"
                :showIcon=true
                :goodFlg="!!featured_lecture.good_flg"
                :wide="!isMobile && index < 2"
                :border-color="
                  featured_lecture.team_color
                    ? `rgb(${featured_lecture.team_color})`
                    : '#7BE293'
                "
                @click="goToLecture(featured_lecture.lecture_id)"
                @select="
                  goodChange(
                    featured_lecture.lecture_id,
                    featured_lecture.good_flg
                  )
                "
              />
            </div>
          </div>
          <div :class="$style.list_button_wrapper" v-if="showLoadMoreLecturesFlg">
            <base-button
              display="inline-block"
              width="240px"
              height="40px"
              size="m"
              rounded="rounded"
              bgColor="green"
              bordered="true"
              @click="handleLoadMore"
              >もっと見る
            </base-button>
          </div>
        </base-accordion>
      </div>

      <div :class="[$style.corner_wrap]">
        <div :class="[$style.accordion_section]">
          <base-accordion title="受講可能なコーナー" type="borderless" bold expanded>
            <div :class="$style.container">
              <div
                v-for="corner in corners"
                :class="$style.item_wrap"
                :key="corner.corner_id"
              >
                <app-carousel-search-result
                  :title="corner.corner_name"
                  :name="corner.course_name"
                  :image="corner.corner_path"
                  :movie=false
                  :time="corner.corner_created_at"
                  :border-color="'#E2E2E2'"
                  @click="goToCorner(corner.corner_id)"
                />
              </div>
            </div>
            <div :class="$style.list_button_wrapper" v-if="showLoadMoreCornersFlg">
              <base-button
                display="inline-block"
                width="240px"
                height="40px"
                size="m"
                rounded="rounded"
                bgColor="green"
                bordered="true"
                @click="fetchCorners(currentPage.corners + 1)"
                >もっと見る
              </base-button>
            </div>
          </base-accordion>
        </div>
      </div>

      <div :class="$style.chart_content_wrap">
          <h1 :class="$style.chart_content_title">あなたの学習ステータス</h1>
          <div :class="$style.check_button_wrap">
            <base-button
              width="200px"
              height="30px"
              bg-color="yellow"
              color="black"
              rounded="rounded"
              @click="$router.push(`/union/leaning_status`)"
            >
              <span :class="$style.check_button_caption"
                >学習状況をチェックする</span
              >
            </base-button>
          </div>
          <div :class="$style.dash_board">
            <div :class="$style.chart_content_chart">
              <app-status-chart
                ref="statusChart"
                :labels="['組合理解', '役割理解', '労働基礎', 'スキル']"
                :data="data"
                :average="average"
              />
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import BaseAccordion from '@/components/base/BaseAccordion/BaseAccordion'
import BaseButton from '@/components/base/BaseButton/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseInput from '@/components/base/BaseInput/BaseInput'
import AppCarouselSearchResult from '@/components/app/AppCarousel/PostItem/AppCarouselSearchResult'
import AppStatusChart from '@/components/app/AppChart/AppStatusChartMovie/AppStatusChart'

export default {
  name: 'UnionIndex',
  components: {
    BaseAccordion,
    BaseButton,
    BaseIcon,
    BaseInput,
    AppCarouselSearchResult,
    AppStatusChart,
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'MOVIE',
      showSetting: false,
      headerColor: 'white',
    })
  },
  data() {
    return {
      searchProcessing: false,
      showSearchAccordion: false,
      keyWord: '',
      isMobile: false,
      itemsPerPage: {
        searchResults: 8,
        featuredLecturesInitial: 6,
        featuredLecturesLoadMore: 8,
        corners: 8, 
      },
      currentPage: {
        searchResults: 1,
        featuredLectures: 1,
        corners: 1,
      },
      searchResults: [],
      featuredLectures: [],
      corners: [],
      hasPrev: false,
      hasNext: false,
      isLoadMore: false,
      data: [0, 0, 0, 0],
      average: [0, 0, 0, 0],
    }
  },
  computed: {
  },
  methods: {
    setupMediaQuery() {
      const mediaQuery = window.matchMedia("(max-width: 767px)");
      this.updateItemsPerPage(mediaQuery.matches);
      mediaQuery.addEventListener("change", (e) => {
        this.updateItemsPerPage(e.matches);
      });
    },
    updateItemsPerPage(isMobile) {
      this.isMobile = isMobile;
      this.itemsPerPage.searchResults = isMobile ? 4 : 8; 
      this.itemsPerPage.featuredLecturesInitial = 6;
      this.itemsPerPage.featuredLecturesLoadMore = isMobile ? 4 : 8;
      this.itemsPerPage.corners = isMobile ? 4 : 8;
    },
    fetchSearchResults(page = 1, isPagination = false) {
      this.showSearchAccordion = true;
      if (!this.keyWord.trim()) {
        return;
      }
      if (!isPagination) {
        this.searchProcessing = true;
      }
      const searchResultsLimit = this.itemsPerPage.searchResults;
      return this.$axios
        .get(`/api/${this.$constants.API_VERSION}/lecture/list`, {
          params: {
            search_word: this.keyWord,
            attention_lecture_flg: 1,
            unread_flg: 0,
            sort: 'updated_at',
            direction: 'desc',
            limit: searchResultsLimit,
            offset: (page - 1) * searchResultsLimit,
          }
        })
        .then((res) => {
          const searchResults = res.data.value.lecture_list;
          // 取得した検索結果が存在するなら
          if (searchResults.length > 0) {
            this.searchResults = searchResults;
            this.currentPage.searchResults = page;
            this.hasPrev = page > 1;
          } else if (isPagination) { // 次へ押下時に存在しない場合
            this.currentPage.searchResults = page - 1;
            this.hasPrev = this.currentPage.searchResults > 1;
          } else { // 検索時に検索結果が存在しない場合
            this.searchResults = [];
            this.currentPage.searchResults = 1;
          }
          this.hasNext = searchResults.length === searchResultsLimit;
          this.searchProcessing = false;
        })
        .catch((err) => {
          if (err.response) {
            this.$logerror(err.response.data)
            this.$logerror(err.response.status)
            this.$logerror(err.response.statusText)
            this.$logerror(err.response.headers)
          }
          this.$logerror(err)
          this.searchProcessing = false;
        })
    },
    handleLoadMore() {
      this.isLoadMore = true;
      this.fetchFeaturedLectures(this.currentPage.featuredLectures + 1);
    },
    fetchFeaturedLectures(page = 1) {
      const featuredLecturesLimit = this.isLoadMore
      ? this.itemsPerPage.featuredLecturesLoadMore
      : this.itemsPerPage.featuredLecturesInitial;
      return this.$axios
        .get(`/api/${this.$constants.API_VERSION}/lecture/list`, {
          params: {
            attention_lecture_flg: 1,
            unread_flg: 0,
            sort: 'updated_at',
            direction: 'desc',
            limit: featuredLecturesLimit,
            offset: this.featuredLectures.length,
          }
        })
        .then((res) => {
          const newLectures = res.data.value.lecture_list;
          if (page === 1) {
            this.featuredLectures = newLectures;
          } else {
            this.featuredLectures = [...this.featuredLectures, ...newLectures];
          }
          this.currentPage.featuredLectures = page;
          this.showLoadMoreLecturesFlg = newLectures.length > 0 && newLectures.length === featuredLecturesLimit;
        })
        .catch((err) => {
          if (err.response) {
            this.$logerror(err.response.data)
            this.$logerror(err.response.status)
            this.$logerror(err.response.statusText)
            this.$logerror(err.response.headers)
          }
          this.$logerror(err)
        })
    },
    fetchCorners(page = 1) {
      const cornersLimit = this.itemsPerPage.corners;
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/corner/list',
        {params:{
          sort: 'created_at',
          direction: 'desc',
          limit: cornersLimit,
          offset: this.corners.length,
        }})
        .then((res) => {
          const newCorners = res.data.value;
          if (page === 1) {
            this.corners = newCorners;
          } else {
            this.corners = [...this.corners, ...newCorners];
          }
          this.currentPage.corners = page;
          this.showLoadMoreCornersFlg = newCorners.length === cornersLimit;
        })
        .catch((err) => {
          if (err.response) {
            this.$logerror(err.response.data)
            this.$logerror(err.response.status)
            this.$logerror(err.response.statusText)
            this.$logerror(err.response.headers)
          }
          this.$logerror(err)
        })
    },
    fetchChartContents() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/attendance_rate/list', {})
        .then((res) => {
          let data = [
            res.data.value.union,
            res.data.value.role,
            res.data.value.scene,
            res.data.value.skill,
          ]
          let average = [
            res.data.value.union_average,
            res.data.value.role_average,
            res.data.value.scene_average,
            res.data.value.skill_average,
          ]
          this.data = data
          this.average = average
        })
        .catch((err) => {
          if (err.response) {
            this.$logerror(err.response.data)
            this.$logerror(err.response.status) // 例：400
            this.$logerror(err.response.statusText) // Bad Request
            this.$logerror(err.response.headers)
          }

          this.$logerror(err)
        })
    },
    async fetchData() {
      if (this.keyWord) {
        this.keyWord = this.keyWord.trim()
      }
      await Promise.all([
        this.fetchFeaturedLectures(),
        this.fetchCorners(),
        this.fetchChartContents()
      ])
    },
    goToCorner(id) {
      this.$router.push(`/union/corner/${id}`)
    },
    goToLecture(lecture_id) {
      this.$router.push(`/union/course/${lecture_id}`)
    },
    goodChange(lecture_id, good_flg) {
      let url = ''
      let postdata = {}
      if (good_flg) {
        good_flg = 0
      } else {
        good_flg = 1
      }
      let filter = []
      url = '/api/' + this.$constants.API_VERSION + '/lecture_history/chat/favorite'
      let chat_users = []
      chat_users.push({
        lecture_id: lecture_id,
        good_flg: good_flg,
      })
      postdata = {
        chat_users: chat_users,
      }

      filter = this.featuredLectures.filter(function (element) {
        return element['lecture_id'] == lecture_id
      })

      this.$axios
        .post(url, postdata)
        .then(() => {
          filter.forEach((element) => {
            if (good_flg == 1) {
              element['good_flg'] = true
            } else {
              element['good_flg'] = false
            }
          })
        })
        .catch((err) => {
          this.$logerror(err)
          alert(err.config)
        })
    },
  },
  async created() {
    this.$router.app.$emit('show-spinner', true)
    this.setupMediaQuery();
    await this.fetchData()
    this.$refs.statusChart.$emit("update-chart");
    this.$router.app.$emit('show-spinner', false)
    this.keyWord = this.$route.query.search_word || this.keyWord
  },
}
</script>

<style lang="scss" module>
.movie_layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 12px 0 0 0;

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    padding: 24px 0 0 0;
  }

  &_loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    width: 100%;
  }
}

.search_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 12px;
  margin:40px 0 0 0;

  @media (max-width: 767px) {
    max-width: calc(100% - 40px);
    padding: 0 20px 0 20px;
    margin:0;
  }

    .search_label {
      width: 150px;
      height: 16px;
      margin: 3px 10px 12px 0;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: normal;
      text-align: left;
      color: #262626;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    .custom_base_input {
      width: 100%;
      max-width: 800px;
      flex-grow: 1;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;

      .search_label {
        width: 125px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
      }
      .custom_base_input {
        max-width: 100%;
      }
    }
}

.pagination_controls {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 127px;
  margin: 0 auto;
  padding: 8px;
  border: 2px solid #4ec2c2;
  border-radius: 8px;
  background-color: white;
  @media (max-width: 767px) {
    justify-content: center;
    margin: 0 auto;
  }

  button {
    font-size: 20px;
    color: #888888;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 8px;
    &:disabled {
      color: #cccccc;
      cursor: not-allowed;
    }
  }
  .current_page {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: white;
    background-color: #4ec2c2;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin: 0 8px;
  }
  .expand_arrow {
    transition: 0.3s;
  }
}

.corner_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: white;
}

.accordion_section {
  width: 100%;
  max-width: 1200px;
  padding: 30px 0 30px 0;

  @media (max-width: 767px) {
    max-width: calc(100% - 40px);
    padding: 30px 20px 30px 20px;
  }

  &.no_results {
    padding: 0 0;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-auto-rows: minmax(200px, auto);
    gap: 32px;
    padding: 0;
    margin: 10px 0 40px 0;
    .item_wrap {
      width: 100%;
      height: auto;
    }
    // カルーセルでwide が当たっている
    .highlighted {
      &:nth-child(1),
      &:nth-child(2) {
        grid-column: span 2;
        height: 180px;
        padding: 0;
        picture {
          height: 105px;
        }
        .text_wrap {
          height: 160px;
        }
      }
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
        .highlighted {
        &:nth-child(1),
        &:nth-child(2) {
          grid-column: span 2;
          height: auto;
          picture {
            width: 100%;
            height: 105px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }
}

.chart_content_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 550px;
  background-color: #f9f9f9;
  padding: 0 0 120px 0;

  @media (max-width: 767px) {
    height: 400px;
    padding-bottom: 32px;
  }

  .chart_content_title {
    font-size: 15px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 11px;
      margin: 0 0 12px 0;
    }
  }

  .check_button_wrap {
    display: flex;

    .check_button_caption {
      font-size: 10px;
      color: $fontBlack;
    }
  }

  .dash_board {
    display: flex;
    align-items: center;
    width: 100%;
    
    .chart_content_chart {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

}

.list_button_wrapper {
  display: flex;
  justify-content: center;
}

</style>
