<template>
  <section :class="$style.union_setting">
    <div :class="$style.union_setting_content">
      <app-prev-button @click="$router.go(-1)" />
      <div :class="$style.filter_wrap">
        <div :class="$style.filter_header">
          <h1 :class="$style.filter_header_title">ゆにおん一覧</h1>
        </div>
        <div :class="$style.filter">
          <div :class="$style.filter_table_container">
              <div :class="$style.filter_item">
                <label for="union_name">ゆにおん名（部分一致）</label>
                <input id="union_name" :class="$style.custom_input" v-model="unionName"/>
                <div v-if="errors.unionName" :class="$style.error">{{ errors.unionName }}</div>
              </div>
              <div :class="$style.filter_item">
                <label for="union_code">ゆにおんコード（完全一致）</label>
                <input id="union_code" :class="$style.custom_input" v-model="unionCode"/>
                <div v-if="errors.unionCode" :class="$style.error">{{ errors.unionCode }}</div>
              </div>
              <div :class="$style.filter_item">
                <label for="team_name">組織名（部分一致）</label>
                <input id="team_name" :class="$style.custom_input" v-model="teamName"/>
                <div v-if="errors.teamName" :class="$style.error">{{ errors.teamName }}</div>
              </div>
              <div :class="$style.filter_item">
                <label for="team_code">組織コード（完全一致）</label>
                <input id="team_code" :class="$style.custom_input" v-model="teamCode"/>
                <div v-if="errors.teamCode" :class="$style.error">{{ errors.teamCode }}</div>
              </div>
          </div>

          <div :class="$style.filter_action_button">
            <base-button
              rounded="rounded"
              width="120px"
              bordered
              @click="resetClick"
            >条件リセット</base-button>
            <base-button
              rounded="rounded"
              width="120px"
              @click="searchClick"
              v-if="!searchProcessing"
            >検索</base-button>
            <div :class="$style.filter_action_button_loading_area" v-else>
              <base-icon name="spinner" size="24px" spin /></div>
          </div>
        </div>
      </div>

      <div :class="$style.list_table_wrap" v-show="!isInitSearch">
        <div :class="$style.list_table_header">
          <h1 :class="$style.list_table_header_title">検索結果</h1>
          <div :class="$style.list_table_header_total">
            <span>{{totalRowCount}}</span>&nbsp;{{totalRowCount != null ? '件' : ''}}
          </div>
        </div>
        <div :class="$style.list_table">
          <p :class="$style.not_found" 
            v-show="!isRows && !searchProcessing">
            データが見つかりませんでした。
          </p>
          <app-table-list
            :col-defs="tableColDefs"
            :row-data="tableRowData"
            :page="tablePageIndex"

            :no-sort="true"
            :fetch-mode="true"
            :page-data-num="perPage"
            :total-record="total"
            :no-margin-b="true"
            :selectable="false"

            @page-no-click="paginate"
            @cell-click="cellClick"
          >
          </app-table-list>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import AppTableList from '@/components/app/AppTableList/AppTableList'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import { mapGetters } from 'vuex';
export default {
  name: 'GroupManegement',
  components: { BaseButton, AppPrevButton, AppTableList, BaseIcon },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'ゆにおん一覧',
      showSetting: false,
      back: '/',
      headerColor: 'white',
      bgColor: 'gray'
    })
    const query = this.$route.query;
    this.unionName = query.union_name || '';
    this.unionCode = query.union_code || '';
    this.teamName = query.team_name || '';
    this.teamCode = query.team_code || '';
    this.tablePageIndex = Number(query.page) || 1;

    if (Object.keys(query).length) {
      this.isInitSearch = false;
      this.search();
    }
  },
  data() {
    return {
      unionName: '',
      unionCode: '',
      teamName: '',
      teamCode: '',
      errors: {},
      tableColDefs: [
        { key: 'union_code', label: 'ゆにおんコード', clickable: true },
        { key: 'union_name', label: 'ゆにおん名', clickable: true },
        { key: 'team_name', label: '所属組織', clickable: true },
        { key: 'storage_count_display', label: 'ストレージ容量', clickable: true, 
          highlightCondition: (rowDat) => rowDat.union_storage_count > rowDat.storage_limit },
        { key: 'member_count_display', label: '人数', clickable: true, 
          highlightCondition: (rowDat) => rowDat.union_member_count > rowDat.people_limit },
      ],
      tableRowData: [],
      tablePageIndex: 1,
      total: null,
      perPage: 10,

      searchProcessing: false,
      processing: false,

      isInitSearch: true,
    }
  },
  methods: {
    searchClick: function () {
      if (!this.validateInputs()) {
        return;
      }
      this.tablePageIndex = 1
      this.search()
    },
    search: async function () {
      if (this.searchProcessing) return
      const params = {};

      if (this.unionName) params.union_name = this.unionName;
      if (this.unionCode) params.union_code = this.unionCode;
      if (this.teamName) params.team_name = this.teamName;
      if (this.teamCode) params.team_code = this.teamCode;
      params.page = this.tablePageIndex;

      this.$router.replace({
        path: this.$route.path,
        query: { ...params }
      }).catch(() => {});

      this.searchProcessing = true
      
      try {
        const result = await this.$axios.get('/api/' + this.$constants.API_VERSION + '/union/search', { params })
        if (result.data && result.data.unions) {
          this.tableRowData = result.data.unions;
          this.tablePageIndex = result.data.pagination.current_page;
          this.total = result.data.pagination.total;
        } else {
          this.tableRowData = []
          this.tablePageIndex = 1
          this.total = 0
        }
      } finally {
        this.searchProcessing = false
        this.isInitSearch = false
      }
    },
    resetClick: async function () {
      this.unionName = '';
      this.unionCode = '';
      this.teamName = '';
      this.teamCode = '';
      this.tablePageIndex = 1;
      this.errors = {}

      this.$router.replace({
          path: this.$route.path,
          query: {}
      }).catch(() => {});
    },
    paginate: function (listPage) {
      this.tablePageIndex = Number(listPage)
      this.search()
    },
    cellClick: async function(cellObj) {
      const { rowDat } = cellObj;
      const targetPath = `/home/group_management/${rowDat.union_code}`;
      if (this.$route.path !== targetPath) {
        this.$router.push({ path: targetPath });
      }
    },
    validateInputs() {
      this.errors = {};
      if (this.unionCode && !/^\d+$/.test(this.unionCode)) {
        this.errors.unionCode = 'ゆにおんコードは数字で入力してください';
      }
      if (this.teamCode && !/^\d+$/.test(this.teamCode)) {
        this.errors.teamCode = '組織コードは数字で入力してください';
      }
      if (this.unionName && this.unionName.length > 50) {
        this.errors.unionName = 'ゆにおん名は50文字以内で入力してください';
      }
      if (this.teamName && this.teamName.length > 255) {
        this.errors.teamName = '組織名は255文字以内で入力してください';
      }
      return Object.keys(this.errors).length === 0;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.getIsGroupManager) {
        vm.$router.push('/error/404');
      }
    });
  },
  computed: {
    totalRowCount(){
      return this.total == null ? '' : this.total;
    },
    isRows() {
      return this.total && this.total > 0
    },
    ...mapGetters('auth', ['getIsGroupManager']),
  },
}
</script>

<style lang="scss" module>
.union_setting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
}
.union_setting_content {
  width: 100%;
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.filter_wrap {
  width: 100%;
  margin-bottom: 34px;

  .filter_header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 26px;
    &_title {
      font-size: 30px;
      margin: 0 auto 0 0;
    }
    & > *:last-child {
      margin-left: 32px;
    }
  }

  .filter {
    width: 100%;
    background-color: $keyWhite;
    border: 2px solid $keyBlack;
    box-sizing: border-box;

    .filter_table_container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      gap: 20px;
      padding: 10px;
      box-sizing: border-box;
    }

    .filter_item {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px;

      label {
        flex: 1;
        min-width: 200px;
        white-space: nowrap;
        font-size: 14px;
        font-weight: bold;
      }

      input {
        flex: 2;
        height: 30px;
        font-size: 16px;
        border: 2px solid #b7b7b7;
        border-radius: 4px;
        padding: 5px;
      }
    }

    &_action_button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      border-top: 1px solid #707070;
      box-sizing: border-box;

      & > *:last-child {
        margin-left: 30px;
      }
      &_loading_area {
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.list_table_wrap {
  width: 100%;
  .not_found {
    margin: 0px 20px 30px;
    font-size: 14px;
    font-weight: 600;
  }
  .list_table_header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 26px;

    &_title {
      font-size: 30px;
      margin: 0 20px 0 0;
    }

    &_total {
      margin-right: auto;
      font-size: 16px;
      span {
        font-weight: bold;
      }
    }

    & > *:last-child {
      margin-left: 20px;
    }
  }
  .list_table th, .list_table td {
    min-width: 150px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .list_table td {
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .list_table tr:hover {
    background-color: #f0f8ff;
    transition: background-color 0.3s;
  }
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>