import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { config, library } from '@fortawesome/fontawesome-svg-core'

/*** import icon ***/
import {
  faAddressCard,
  faPencilAlt,
  faCheckSquare,
  faTimes,
  faHome,
  faSearch,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faQuestion,
  faEllipsisH,
  faBars,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faAt,
  faHeart as fasHeart,
  faPlus,
  faMinus,
  faCommentDots,
  faAngleRight,
  faEye,
  faEyeSlash,
  faEnvelope,
  faMobileAlt,
  faLock,
  faExchangeAlt,
  faLink,
  faPlay,
  faPlayCircle,
  faCheck,
  faSort,
  faSortUp,
  faSortDown,
  faSpinner,
  faTrashAlt as fasTrashAlt,
  faPeopleArrows,
} from '@fortawesome/free-solid-svg-icons'
import {
  faSquare,
  faBell,
  faHeart as farHeart,
  faStar as farStar,
  faPlusSquare,
  faMinusSquare,
  faSmile,
  faFrown,
  faTrashAlt as farTrashAlt,
} from '@fortawesome/free-regular-svg-icons'
import {} from '@fortawesome/free-brands-svg-icons'

/*** add Library ***/
const solidIcons = [
  faAddressCard,
  faPencilAlt,
  faCheckSquare,
  faTimes,
  faHome,
  faSearch,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faQuestion,
  faEllipsisH,
  faBars,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faAt,
  fasHeart,
  faPlus,
  faMinus,
  faAngleRight,
  faCommentDots,
  faEye,
  faEyeSlash,
  faEnvelope,
  faMobileAlt,
  faLock,
  faExchangeAlt,
  faLink,
  faPlay,
  faPlayCircle,
  faCheck,
  faSpinner,
  fasTrashAlt,
  faPeopleArrows,
]
const regularIcons = [
  faSquare,
  faBell,
  farHeart,
  farStar,
  faPlusSquare,
  faMinusSquare,
  faSmile,
  faFrown,
  faSort,
  faSortUp,
  faSortDown,
  farTrashAlt,
]
const brandsIcons = []

solidIcons.forEach((icon) => library.add(icon))
regularIcons.forEach((icon) => library.add(icon))
brandsIcons.forEach((icon) => library.add(icon))

/**
 * prevent runtime css loading
 * issue:https://blog.mono0x.net/2020/06/16/react-fontawesome/
 **/
config.autoAddCss = false

export default FontAwesomeIcon
