var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    _vm.$style.wrapper,
    _vm.wide ? _vm.$style.wide : ''
  ],style:(_vm.customStyle),on:{"click":_vm.handleClick}},[_c('picture',{class:[
      _vm.$style.picture,
      _vm.movie ? ("" + (_vm.$style.show_after)) : ''
    ],attrs:{"border-color":_vm.borderColor,"outline-color":_vm.outlineColor}},[_c('img',{attrs:{"src":_vm.image}})]),_c('div',{class:_vm.$style.text_wrap},[_c('div',{class:_vm.$style.info_wrap},[_c('p',{class:_vm.$style.title_annotation},[(_vm.time)?_c('time',{class:_vm.$style.time_text,attrs:{"datetime":_vm.timeAttr}},[_vm._v(" "+_vm._s(_vm.timeText)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.titleAnnotation)+" ")]),(_vm.showIcon)?_c('span',{class:[_vm.$style.icons_wrapper, ( _obj = {}, _obj[_vm.$style.selected] = !!_vm.goodFlg, _obj )],on:{"click":function($event){$event.stopPropagation();return _vm.$emit('select', _vm.lectureId)}}},[_c('base-icon',{attrs:{"type":"fas","name":"heart","color":"iconColor","size":"10px"}})],1):_vm._e()])]),_c('div',{class:[_vm.$style.title_wrap]},[_c('p',{class:_vm.$style.title},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('p',{class:_vm.$style.name},[_vm._v(" "+_vm._s(_vm.name)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }