<template>
    <base-modal
      @close="$emit('close', replyText, commentId, replyId)"
      type="round"
      :class="$style.reply_modal"
      :hideContentHeader="true"
    >
      <template v-slot:body>
        <div :class="$style.post_comment_form">
          <div :class="$style.post_comment_form_label">コメント</div>
          <div :class="$style.post_comment_form_content">
            <div>
              <base-avatar
                size="40px"
                :border-color="color"
                :bordered="!!color"
                :path="userImagePath"
              />
            </div>
            <div :class="$style.info_wrapper">
              <div :class="$style.name">{{ name }}</div>
              <span :class="$style.text">{{ dateString }}</span>
            </div>
          </div>
          <div :class="$style.content_wrap">
            <p :class="$style.content_body">
              <span v-if="type == 1" :class="$style.body" v-html="$sanitize(body)">
              </span>
              <span v-else-if="type == 2 || type == 4">
                <span v-if="pathList" :class="$style.picture">
                  <span v-if="fileType(pathList) == 'image'">
                    <img :src="fileUrl(pathList)" alt="" width="100%" height="100%"/>
                  </span>
                  <span v-else-if="fileType(pathList) == 'file'">
                    <button :class="$style.file_download" style="cursor: pointer" @click.prevent.stop="$emit('download', pathList[0].path)">
                      <img
                        :class="$style.file_icon"
                        src="@/assets/images/file_download.svg"
                        alt="icon"
                      />
                      {{ filePath(pathList) }}
                    </button>
                  </span>
                  <span v-else-if="fileType(pathList) == 'audio_file'">
                    <audio :src="fileUrl(pathList)" controls="" style="width: 260px">
                      非対応
                    </audio>
                  </span>
                </span>
              </span>
              <span v-else-if="type == 3" :class="$style.stamp">
                <img :src="stampUrl" alt="" />
              </span>
            </p>
            <a
              v-if="(fileType(pathList) == 'image' || fileType(pathList) == 'file')"
              :class="$style.download"
              href="#"
              @click.prevent.stop="$emit('download', pathList[0].path)"
            >
              ダウンロードする
            </a>
          </div>
          <base-select-taggable
            name="to_personal"
            :options="to_personal_options"
            v-model="to_personal_selected"
            multiple
            :loading="to_personal_loading"
            placeholder="コメントをお知らせしたいメンバー"
            :error="isFieldErrorMsg('to_personal', errorMsgList)"
            :selectable="selectableOption"
            :is_exist_choicesLabel="true"
            :close-on-select="false"
          >
          </base-select-taggable>
          <base-input-comment
            name="comment"
            :showStampIcon="true"
            stampIconName="stamp_03.svg"
            :showRecordIcon="false"
            :showFileIcon="false"
            @update="setComment"
            @text_changed="onChangeText"
            @text_length="getTextLength"
            @stamp="postStamp"
            :textareaValue="replyText"
            :commentErrorList="commentErrorList"
            :rows="10"
          />
          <div :class="$style.title_text">{{textLength}}/5000</div>
          <div :class="$style.member_avatar_list">
            <div v-for="user in to_personal_selected" :key="user.user_id">
              <div :class="$style.member_avatar_icon">
                  <base-avatar size="60px" :path="user.profile_image_path ? user.profile_image_path : NoImage"/>
              </div>
            </div>
          </div>
          <base-input-comment
            name="attached_file"
            :showStampIcon="false"
            :showRecordIcon="false"
            :showTextArea="false"
            fileIconName="upload_file.svg"
            @file_updated="onFileSubmit"
            @file_delete="onFileDelete"
            @error="onFileError"
            textareaValue="添付ファイルを追加"
            :maxFileSize="50"
            :showTrash="true"
            :files="files"
            :imageTypes="imageTypes"
          />
          <div :class="$style.post_comment_form_button">
            <div>
              <base-button
                bordered
                rounded="rounded"
                width="150px"
                @click="$emit('close', replyText, commentId, replyId)"
              >キャンセル</base-button>
            </div>
            <div>
              <base-button rounded="rounded" width="150px" @click="sendPost">送信</base-button>
            </div>
          </div>
        </div>
      </template>
    </base-modal>
</template>

<script>
import BaseAvatar from '../../base/BaseAvatar/BaseAvatar.vue'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import { autoLink, getBase64FromDataUrl } from '@/utils/helper.js'
import { isFieldErrorMsg } from "@/helper/validator.js";
import BaseInputComment from "@/components/base/BaseInputComment/BaseInputComment";
import BaseSelectTaggable from "@/components/base/BaseSelectTaggable/BaseSelectTaggable";
import NoImage from '@/assets/images/account-circle.svg';

export default {
  components: {
    BaseAvatar,
    BaseModal,
    BaseButton,
    BaseInputComment,
    BaseSelectTaggable,
  },
  name: 'AppReplyModal',
  props: {
    type: {
      type: Number,
    },
    /** 本文 */
    body: {
      type: String,
      default: undefined,
    },
    /** ユーザー画像パス。BaseAvatarに準じる */
    userImagePath: {
      type: String,
    },
    /** アバター色 */
    color: {
      type: String,
    },
    /** コメントID */
    commentId: {
      type: Number,
      default: null,
    },
    chatId: {
      type: Number,
    },
    replyId: {
      type: Number,
      default: null,
    },
    /** 投稿者名前 */
    name: {
      type: String,
    },
    /** リプライ先の投稿者ID */
    mentionId: {
      type: String,
    },
    /** 添付ファイル  */
    pathList: {
      type: Array,
      default: null,
    },
    /** スタンプURL */
    stampUrl: {
      type: String,
      default: null,
    },
    dateString: {
      type: String,
    },
    bodyColor: {
      type: String,
      default: '#FFFFFF',
    },
    to_personal_options: {
      type: Array,
    },
    to_personal_loading: {
      type: Boolean,
    },
    savedText: {
      type: String,
      default: '',
    },
    savedCommentId: {
      type: Number,
      default: null,
    },
    savedReplyId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      processing: false,
      showCommentModal: false,
      showImage: false,
      textLength: 0,
      errorMsgList: [],
      plainText: '',
      replyText: this.setInitialReplyText(),
      stampId: null,
      files: [],
      file_list: [],
      imageTypes: [
        'image', // 登録済みの画像形式
        'image/apng', // APNG
        'image/avif', // AVIF
        'image/gif', // GIF
        'image/jpeg', // JPEG
        'image/png', // PNG
        'image/svg+xml', // SVG
        'image/webp', // WebP
      ],
      mentions: [],
      mentionTo: '',
      to_personal_selected: [],
      linkStyles: {
        color: 'gray',
        fontSize: '9pt',
        textAlign: 'center',
        width: '287px',
      },
      NoImage,
      TIMEOUT: 50,
    }
  },
  mounted() {
    this.setDisableToMentionTarget()
  },
  methods: {
    isFieldErrorMsg,
    setInitialReplyText() {
      if (this.savedCommentId === this.commentId
          || this.savedReplyId === this.replyId) {
        return this.savedText
      } else {
        return ''
      }
    },
    setDisableToMentionTarget() {
      this.to_personal_options.forEach(option => {
        if (option['juid'] === this.mentionId) {
          this.to_personal_selected = [option]
        }
      })
    },
    selectableOption(option) {
      return !option.disabled
    },
    handleSpam() {
      //スパム通報イベント
      this.$emit('spam', this.item)
    },
    commentEdit() {
      this.$emit('editComment', this.commentId, this.body)
    },
    commentDelete() {
      this.$emit('deleteComment', this.commentId)
      this.showCommentModal = false
    },
    fileType: function (path_list) {
      if (!path_list || path_list.length == 0) return ''

      return path_list[0].file_type
    },
    filePath: function (path_list) {
      if (!path_list || path_list.length == 0) return ''

      return path_list[0].path.substring(path_list[0].path.lastIndexOf('/') + 1)
    },
    fileUrl: function (path_list) {
      if (!path_list || path_list.length == 0) return ''

      return path_list[0].file_url
    },
    setMentions() {
      this.mentions = []
      if (this.to_personal_selected.length == 0) {
        // メンション対象が選択されていない場合はreturn
        return true
      }

      // メンション対象ユーザーのuser_idの配列を作成する
      this.mentions = this.to_personal_selected.map(function(selected){
        return { user_id: selected.id }
      })

      return true
    },
    setComment: function (value) {
      this.errorMsgList = []
      this.replyText = value
    },
    onChangeText: function (text) {
      this.plainText = text;
    },
    getTextLength: function (value) {
      this.textLength = value
    },
    postStamp: function (args) {
      if (!this.setMentions()) return
      this.errorMsgList = []
      this.stampId = args.id
      this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/team/chat/comment/reply/registration',
          {
            comment_id: this.commentId,
            comment_reply_id: this.replyId ?? null,
            chat_id: this.chatId,
            type: 3,
            stamp_id: this.stampId,
            mentions: JSON.parse(JSON.stringify(this.mentions)),
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.$emit('send')
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            this.errorMsgList = e.response.data.value.errors
          }
        })
        .finally(() => {
          this.processing = false
          setTimeout(() => {
            this.$router.app.$emit('show-spinner', false)
          }, this.TIMEOUT);
        })
    },
    onFileSubmit([data_url, file]) {
      this.errorMsgList = []
      if (1 <= this.files.length) {
        this.errorMsgList.push({
          attribute: 'path_list',
          message: '添付ファイルは1件まで登録できます。',
        })
        return
      }

      this.files.push({
        id: '',
        type: file.type,
        name: file.name,
        url: data_url,
        is_new: true,
      })
    },
    onFileDelete(file, index) {
      file
      index
      this.errorMsgList = []
      this.files = []
    },
    onFileError(errors) {
      this.errorMsgList = []
      if (errors.filesize) {
        this.errorMsgList.push({
          attribute: 'path_list',
          message: '添付ファイルのファイルサイズの上限は50Mbyteです。',
        })
      }
    },
    sendPost() {
      if (!this.setMentions()) return
      if (this.files.length == 0) {
        this.postReply(true)
      } else {
        this.postFile()
      }
    },
    postFile: function () {
      if (this.processing) return
      this.processing = true
      this.$router.app.$emit('show-spinner', true)
      this.heavyProcess()
    },
    heavyProcess() {
      this.errorMsgList = []
      let extension = this.files[0].name.substring(
        this.files[0].name.lastIndexOf('.')
      )
      let file_type = ''
      let comment_type = null

      if (
        extension.toUpperCase().match(/\.(jpg)$/i) ||
        extension.toUpperCase().match(/\.(jpeg)$/i) ||
        extension.toUpperCase().match(/\.(png)$/i) ||
        extension.toUpperCase().match(/\.(gif)$/i)
      ) {
        file_type = 'image'
        comment_type = 2
      } else if (extension.toUpperCase().match(/\.(aac)$/i)) {
        file_type = 'audio_file'
        comment_type = 4
      } else {
        file_type = 'file'
        comment_type = 2
      }
      var convert_base64 = getBase64FromDataUrl(this.files[0].url)
      this.file_list.push({
        file_name: this.files[0].name,
        file: convert_base64,
        file_type: file_type,
      })

      this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/team/chat/comment/reply/registration',
          {
            comment_id: this.commentId,
            comment_reply_id: this.replyId ?? null,
            chat_id: this.chatId,
            type: comment_type,
            file_list: this.file_list,
            mentions: JSON.parse(JSON.stringify(this.mentions)),
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            if (this.replyText.length != 0) {
              this.postReply()
            } else {
              this.$emit('send')
            }
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            this.errorMsgList = e.response.data.value.errors
          }
        })
        .finally(() => {
          this.processing = false
          if (this.replyText.length == 0) {
            setTimeout(() => {
              this.$router.app.$emit('show-spinner', false)
            }, this.TIMEOUT);
          }
        })
    },
    postReply: function (toggle_processing = false) {
      this.$router.app.$emit('show-spinner', true)
      if (toggle_processing) {
        if (this.processing) return
        this.processing = true
      }

      this.errorMsgList = []
      let formatted_text_web = this.replyText

      // メンション対象ユーザーにmentionタグを付ける
      let mention_string = this.getSelectedUserWrapInMentionTag()

      formatted_text_web = mention_string + formatted_text_web

      this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/team/chat/comment/reply/registration',
          {
            comment_id: this.commentId,
            comment_reply_id: this.replyId ?? null,
            chat_id: this.chatId,
            type: 1,
            text: this.plainText,
            formatted_text_web: formatted_text_web,
            mentions: JSON.parse(JSON.stringify(this.mentions)),
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.$emit('send')
            this.to_personal_selected = []
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            this.errorMsgList = e.response.data.value.errors
          }
        })
        .finally(() => {
          this.processing = false
          setTimeout(() => {
            this.$router.app.$emit('show-spinner', false)
          }, this.TIMEOUT);
        })
    },
    /**
     * メンション対象ユーザーに選択されているユーザーのニックネームを
     * mentionタグで囲んだ文字列を取得する
     */
    getSelectedUserWrapInMentionTag: function () {

      if (this.to_personal_selected.length == 0){
        return ''
      }

      let wrapped_list = this.to_personal_selected.map(function(selected){
        return `<mention juid="${selected.juid}">@${selected.nickname}</mention>`
      })

      let result = wrapped_list.join(' ')
      result = '<p>' + result + '</p>'
      return result
    },
    autoLink
  },
  computed: {
    customStyle() {
      return {
        'background-color': `${this.bodyColor}`,
      }
    },
    commentErrorList: function() {
      return !this.errorMsgList? [] : this.errorMsgList.filter((err)=> err.attribute != 'mentions')
    },
  },
}
</script>

<style lang="scss" module>

  .post_comment_form{
    max-width: 1000pt;
    width: 93.75%;
    margin: 30px auto 20px;

    &_wrap{
      width: 83.194%;
      max-width: 1062px;
      margin: 5px auto 0;
    }
    &_label{
      font-weight: bold;
      margin-bottom: 8px;
    }
    &_textarea{
      resize: none;
      width: 100%;
      height: 225px;
      padding: 0;
      border: 2px solid #C4C4C4;
      border-radius: 4px;
      box-sizing: border-box;
    }
    &_attached_file{
      display: flex;
      gap: 0 10px;
      margin-top: 10px;
      .icon{
        display: flex;
        img{
          display: block;
        }
      }
      div:last-child{
        border-bottom: solid 1px;
      }
    }
    &_button{
      display: flex;
      justify-content: center;
      gap: 10px 15px;
      margin-top: 25px;
      flex-wrap: wrap;
    }
    &_content{
      display: flex;
      margin-bottom: 1em;
      .info_wrapper{
        display: flex;
        font-size: 16px;
        align-items: center;
        .name{
          font-weight: bold;
        }
        .text {
          margin-left: 0.5em;
          font-size: 12px;
          color: #707070;
        }
      }
    }
    .content_wrap {
      margin-bottom: 1em;
      .download {
        color: #4AC0C0;
      }
      .body {
        display: block;
        word-wrap: break-word;
        word-break: break-word;
      }
      .body img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .file_download {
    width: 160px;
    border: 1px solid #707070;
    background-color: $keyWhite;
    padding: 4px 4px;
    color: #000000;
    text-decoration: none;
    text-align: left;

    .file_icon {
      display: inline-block;
      padding-right: 6px;
      width: 16px;
      height: 20px;
      vertical-align: middle;
    }
  }
  .title_text {
    text-align: right;
    color: #707070;
  }
  .member_avatar_list {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-left: 12px;
  }
  .member_avatar_icon {
    text-align: left;
    margin: 5px;
  }
</style>
