export default {
  namespaced: true,
  state: {
    tel_or_email: '',
    juid: '',
    user_id: '',
    is_group_manager: false,
  },
  mutations: {
    setTelOrEmail(state, tel_or_email){
      state.tel_or_email = tel_or_email
    },
    setJuid(state, juid) {
      state.juid = juid
    },
    setUserId(state, user_id){
      state.user_id = user_id
    },
    setIsGroupManager(state, is_group_manager) {
      state.is_group_manager = is_group_manager;
    },
  },
  getters: {
    getTelOrEmail (state) {
      return state?.tel_or_email
    },
    getJuid(state) {
      return state?.juid
    },
    getUserId(state) {
      return state?.user_id
    },
    getIsGroupManager(state) {
      return state?.is_group_manager;
    },
  },
}
