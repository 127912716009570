<template>
  <div :class="$style.status_chart">
    <div :class="$style.status_chart_wrapper">
      <radar-chart :chart-data="radarData" :options="radarOptions" />
    </div>
  </div>
</template>

<script>
import RadarChart from './RadarChart'
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "AppStatusChart",
  components: {
    RadarChart,
  },
  mixins: [ isMobile() ],
  props: {
    data: {
      type: Array,
    },
    labels: {
      type: Array,
    },
    average: {
      type: Array,
    },
  },
  data() {
    return {
      radarData: null,
      radarOptions: null,
      height: '220px',
      width: '335px',
    };
  },
  mounted() {
    this.initializeChart();
    this.$on("update-chart", this.initializeChart);
  },
  methods: {
    initializeChart() {
      this.radarData = {
        labels: this.labels,
        datasets: [
          {
            label: "average",
            data: this.average,
            fill: true,
            backgroundColor: "rgba(0, 241, 213, 0.5)",
            borderColor: "rgb(0, 241, 213)",
            borderWidth: 2,
            pointRadius: this.isMobile ? 1 : 2,
          },
          {
            label: "your-status",
            data: this.data,
            fill: true,
            backgroundColor: "rgba(109, 233, 50, 0.5)",
            borderColor: "rgb(109, 233, 50)",
            borderWidth: 2,
            pointRadius: this.isMobile ? 1 : 2,
          },
        ],
      };

      this.radarOptions = {
        scale: {
          ticks: {
            fontSize: this.isMobile ? 6 : 10,
            max: 100,
            min: 0,
            stepSize: 10,
          },
          pointLabels: {
            fontSize: this.isMobile ? 12 : 18,
            fontColor: '#333',
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          titleFontSize: this.isMobile ? 9 : 12,
          bodyFontSize: this.isMobile ? 9 : 12,
          callbacks: {
            title: function () {
              return "再生完了率";
            },
            label: function (tooltipItem, data) {
              const idx = tooltipItem.index;
              const label = data.labels[idx];
              return label + ':' + tooltipItem.value;
            },
          },
        },
        plugins: {
          customBackgroundPlugin: {
            color: "white",
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" module>
.status_chart {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .status_chart_wrapper {
    width: 500px;
    height: 400px;
    background-color: #f9f9f9;

    @media (max-width: 767px) {
      width: 330px;
      height: 270px;
    }
  }
}
</style>
