<template>
  <section :class="$style.home_profile_wrapper">
    <div :class="$style.home_profile">
      <app-prev-button @click="$router.go(-1)" />

      <div :class="$style.member_avatar_wrap">
        <div :class="$style.member_avatar">
          <base-avatar
            size="120px"
            :path="user.profile_image_path ? user.profile_image_path : NoImage"
          />
        </div>
        <h1 :class="$style.member_nickname">{{ user.nickname }}</h1>
        <p :class="$style.member_name">
          {{ user.name }}
          <span v-if="user.furigana">({{ user.furigana }})</span>
        </p>
        <p
          :class="$style.member_name"
          v-if="!user_id && !user.display_name_flg"
        >
          ※本名は他ユーザーからは閲覧できません
        </p>
      </div>
      <div
        :class="$style.invite_button_wrap"
        v-if="
          $store.state.auth.user_id !== user.user_id &&
          invitable_teams.length > 0
        "
      >
        <base-button
          width="100%"
          rounded="corner"
          display="inline-flex"
          @click="showInvite = true"
          >マイチームに招待する</base-button
        >
      </div>

      <dl :class="$style.home_profile_list">
        <div :class="$style.home_profile_list_item">
          <dt>所属:</dt>
          <dd>{{ team_affiliation }}</dd>
        </div>
        <div :class="$style.home_profile_list_item">
          <dt>ju-ID:</dt>
          <dd>{{ user.juid }}</dd>
        </div>
      </dl>

      <p :class="$style.note" v-html="autoLink(user.open_profile)"></p>

      <template v-if="$store.state.auth.user_id === user.user_id">
        <div :class="$style.edit_button_wrap">
          <base-button
            width="100%"
            rounded="corner"
            display="inline-flex"
            @click="$router.push('/home/profile_edit')"
            >プロフィールを編集する
          </base-button>
        </div>
      </template>

      <div :class="$style.team_accordion_wrap">
        <base-accordion title="Myチーム" type="borderless" expanded>
          <div :class="$style.team_item_list">
            <div
              v-for="team of teams"
              :class="$style.team_item_wrap"
              :key="team.team_id"
            >
              <app-carousel-team-item
                category="life"
                :head="team.name ? team.name : 'ヘッダー'"
                :image="
                  team.path ? team.path : 'https://via.placeholder.com/140x50'
                "
                :border-color="
                  team.team_color ? `rgb(${team.team_color})` : '#7BE293'
                "
                @click="$router.push('/team/info/' + team.team_id)"
              />
            </div>
          </div>
        </base-accordion>
      </div>
      <div v-if="!$store.state.auth.user_id"></div>
      <div
        :class="$style.report_button_wrap"
        v-else-if="isUserExists && $store.state.auth.user_id !== user.user_id"
      >
        <base-button
          width="100%"
          rounded="corner"
          display="inline-flex"
          :bg-color="user.is_user_reported ? 'light-gray' : 'red'"
          :disabled="user.is_user_reported ? true : false"
          @click="showReport = true"
          >{{ user.is_user_reported ? '通報済' : '通報する' }}</base-button
        >
      </div>
      <p v-if="!isUserExists" :class="$style.note">
        ※このユーザは現在アカウントが存在しません。
      </p>
      <base-modal
        v-if="showReport"
        width="287px"
        type="round"
        @close="showReport = false"
      >
        <template v-slot:body>
          <div :class="$style.report_modal_content">
            <h1 :class="$style.report_modal_content_header">
              {{ user.nickname }}さんを通報します
            </h1>
            <p :class="$style.report_modal_content_description">
              通報後、j.unionにて判断しユーザーの停止、削除などの対応を取らせて頂きます。
            </p>
            <div>
              <base-button
                width="185px"
                rounded="rounded"
                bg-color="red"
                @click="reportUser"
                >通報する</base-button
              >
            </div>
          </div>
        </template>
      </base-modal>
      <base-modal v-if="showInvite" type="round" @close="showInvite = false">
        <template v-slot:header-title>招待するチームを選択</template>
        <template v-slot:body>
          <div :class="$style.modal_content">
            <p :class="$style.invite_check_note">
              招待可能なチーム一覧です。<br />すでにメンバーの場合は表示されません。
            </p>
            <div
              v-for="team of invitable_teams"
              :key="team.team_id"
              :class="$style.invite_check_wrap"
            >
              <base-check-box
                name="invite-check"
                v-model="invite_teams"
                :value="team.team_id"
                >{{ team.name }}</base-check-box
              >
            </div>
            <template v-if="error_invite_team.length > 0">
              <base-message
                type="error"
                v-for="(error, i) in error_invite_team"
                :key="i"
                :class="$style.msg_area"
                >{{ error }}</base-message
              >
            </template>
          </div>
        </template>
        <template v-slot:footer>
          <base-button width="185px" rounded="rounded" @click="invitation"
            >招待する</base-button
          >
        </template>
      </base-modal>
    </div>
    <template v-if="$store.state.auth.user_id === user.user_id">
      <div :class="$style.chart_content_wrap">
        <h1 :class="$style.chart_content_title">MY SCORE</h1>
        <div :class="$style.dash_board">
          <div :class="$style.chart_content_chart">
            <!-- スコアが1以上の場合はスコアを表示する -->
            <app-score-chart
              :labels="['暮らし', '仕事', '職場', 'その他']"
              :data="data"
              :score="score"
              :average="average"
              v-if="score"
            />
            <!-- スコアが０の場合は画像を表示する処理を記載 -->
            <img v-else src="@/assets/images/score_0.svg" />
          </div>
          <a
            @click="
              $router.push({
                path: '/home/team',
                params: { team_id: team_id },
              })
            "
            :class="$style.dash_board_arrow_right"
          >
            <base-icon name="chevron-right" color="yellow" size="35px" />
          </a>
        </div>
      </div>
    </template>
  </section>
</template>
<script>
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseAvatar from '@/components/base/BaseAvatar/BaseAvatar'
import BaseAccordion from '@/components/base/BaseAccordion/BaseAccordion'
import AppCarouselTeamItem from '@/components/app/AppCarousel/TeamItem/AppCarouselTeamItem'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import BaseCheckBox from '@/components/base/BaseCheckBox/BaseCheckBox'
import { autoLink } from '@/utils/helper.js'
import NoImage from '@/assets/images/account-circle.svg'
import AppScoreChart from '@/components/app/AppChart/AppScoreChart/AppScoreChart'

export default {
  name: 'HomeProfile',
  components: {
    AppCarouselTeamItem,
    BaseAccordion,
    BaseAvatar,
    BaseButton,
    BaseMessage,
    BaseModal,
    BaseCheckBox,
    AppScoreChart,
  },
  props: {
    user_id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'プロフィール',
      showSetting: false,
      back: this.prevRoute,
      bgColor: 'white',
    })
  },
  data() {
    return {
      /**
       * 各種ボタンの設定
       * approval-member
       * exclude-member
       * appoint-co-admin
       * detach-co-admin
       */
      // type: 'approval-member',
      NoImage,
      error_invite_team: [],
      type: 'exclude-member',
      showInvite: false,
      user: {},
      team_affiliation: '',
      teams: [],
      invitable_teams: [],
      invite_teams: [],

      inviting: false,
      prevRoute: '',
      showReport: false,
      isUserExists: true,
      // MYスコア
      data: [20, 20, 20, 20],
      score: 20,
      average: 0,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  async created() {
    this.$router.app.$emit('show-spinner', true)
    await this.getUser()
    await this.getTeams()
    await this.fetchUserScoreInfo()

    if (this.$store.state.auth.user_id !== this.user.user_id) {
      await this.getInvitableTeams()
    }
    this.$router.app.$emit('show-spinner', false)
  },
  methods: {
    async fetchUserScoreInfo() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/score/info', {})
        .then((res) => {
          let data = [
            res.data.value.user.live_score,
            res.data.value.user.work_score,
            res.data.value.user.scene_score,
            res.data.value.user.other_score,
          ]
          this.score = res.data.value.user.user_sum_score
          this.data = data
          this.average = res.data.value.user.deviation_score
        })
    },
    async getUser() {
      await this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/info', {
          params: {
            user_id: this.user_id || null,
          },
        })
        .then((res) => {
          this.user = res.data.value.user
          this.isUserExists = true
        })
        .catch(() => {
          this.isUserExists = false
          this.user.user_id = null
          this.user.nickname = '-'
          this.user.name = '-'
        })
    },
    async getTeams() {
      let team_value = {}

      if (this.user.user_id != null) {
        let params = {
          user_id: this.user.user_id,
          search_type: 'login',
          sort: 'name',
          direction: 'asc',
          limit: 500,
        }

        if (this.$store.state.auth.user_id !== this.user.user_id) {
          // IRET_JUNION_UNI_VERSE-1882
          // 他のユーザーからプロフィールを表示した場合は検索対象としないチームは表示対象外
          params.open_flg = 1
        }

        await this.$axios
          .get('/api/' + this.$constants.API_VERSION + '/team/list', { params })
          .then((res) => {
            team_value = res.data.value
            this.team_affiliation = team_value.affiliation
            this.teams = team_value.team_list
          })
      }
    },
    async getInvitableTeams() {
      let team_value = {}

      if (this.user.user_id != null) {
        await this.$axios
          .get('/api/' + this.$constants.API_VERSION + '/team/list/invite', {
            params: {
              invite_user_id: this.user_id,
              sort: 'name',
              direction: 'asc',
              limit: 500,
            },
          })
          .then((res) => {
            team_value = res.data.value
            this.invitable_teams = team_value.team_list
          })
      }
    },
    async invitation() {
      if (this.inviting) {
        return
      }
      this.inviting = true

      let invite_flow_list = []

      this.invite_teams.forEach(
        function (v) {
          const data = {
            user_id: this.user.user_id,
            type: 'team_admin_url',
            team_id: v,
          }

          invite_flow_list.push(data)
        }.bind(this)
      )

      if (invite_flow_list.length === 0) {
        this.inviting = false
        return
      }

      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/invite/mail', {
          invite_flow_list: invite_flow_list,
        })
        .then(() => {
          this.showInvite = false
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.error_invite_team.splice(0)
            err.response.data.value.errors.forEach((error) => {
              this.error_invite_team.push(error.message)
            })
            this.inviting = false
            return
          }
        })

      this.inviting = false
    },
    reportUser: async function () {
      if (this.user.is_user_reported) {
        return
      }
      // 通報APIの呼び出し
      await this.callReportUser()
      // 通報済みに更新
      this.user.is_user_reported = true
      // モーダルを非表示
      this.showReport = false
      return
    },
    callReportUser: async function () {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/report', {
          reported_user_id: this.user.user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            // alert('管理者が内容を確認し、24時間以内に対応を致します。')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    autoLink,
  },
}
</script>
<style lang="scss" module>
.home_profile_wrapper {
  display: flex;
  gap: 60px;
  justify-content: center;
  @media (max-width: 768px) {
    gap: 30px;
    flex-direction: column;
    padding: 20px;
  }
}
.home_profile {
  display: flex;
  // width: 60%;
  max-width: 526px;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 50px 0;
  // margin: 0 auto;
  overflow: hidden;

  @include mobile() {
    width: 375px;
    margin: 0 auto;
    width: 100%;
    // padding: 24px 40px 24px 40px;
  }
}

.member_avatar_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 26px;
  @include mobile() {
    margin-bottom: 24px;
  }

  .member_avatar {
    margin-bottom: 30px;
    @include mobile() {
      margin-bottom: 20px;
    }
  }

  .member_nickname {
    font-size: 30px;
    color: $fontBlack;
    margin-top: 0;
    margin-bottom: 4px;
    @include mobile() {
      font-size: 20px;
    }
  }

  .member_name {
    font-size: 13px;
    color: $fontBlack;
    margin: 0;
    @include mobile() {
      font-size: 10px;
    }
  }
}

.edit_button_wrap,
.invite_button_wrap,
.report_button_wrap {
  width: 100%;
  box-sizing: border-box;
  // padding: 0 320px;
  margin-bottom: 30px;

  @include mobile() {
    padding: 0;
  }
}

.home_profile_list {
  padding: 0;
  margin: 0 0 42px 0;
  // width: 42%;
  max-width: 500px;

  @include mobile() {
    width: 100%;
    margin: 0 0 24px 0;
  }

  &_item {
    display: flex;
    flex-wrap: wrap;

    dt,
    dd {
      font-size: 13px;
      color: $fontGray;
      line-height: 24px;
      margin: 0;

      @include mobile() {
        font-size: 10px;
      }
    }

    dt {
      width: 28%;
      white-space: nowrap;
      @include mobile() {
        width: 26%;
      }
    }

    dd {
      width: 72%;
      @include mobile() {
        width: 74%;
      }
    }
  }
}

.note {
  margin: 0 0 30px 0;
  font-size: 13px;
  color: $fontBlack;
  white-space: pre-wrap;
  @include mobile() {
    font-size: 11px;
  }
}

.team_accordion_wrap {
  width: 100%;

  .team_item_list {
    padding: 0;
    margin: 30px 0 0 0;
    display: flex;
    gap: 20px 10px;
    flex-wrap: wrap;

    @include mobile() {
      margin: 10px 0 0;
    }

    // 右端下端のマージンを打ち消す。ピッタリだと影が消えるので少し小さく取る
    // margin-right: -32px;
    // margin-bottom: -10px;
    // @include mobile(){
    //   margin-right: -40px;
    //   margin-bottom: -20px;
    // }

    .team_item_wrap {
      // margin-right: 20px;
      // margin-bottom: 14px;
    }
  }
}

.modal_content {
  margin: 20px auto;
  width: 400px;
  @include mobile() {
    width: auto;
    margin: 20px;
  }

  .invite_check_note {
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 11px;
  }
  .invite_check_wrap {
    margin-bottom: 12px;
  }
}
.msg_area {
  text-align: center;
  width: 100% !important;
  margin-top: 10px;
}

.report_modal_content {
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_header {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 24px;
  }

  &_description {
    margin-top: 0;
    margin-bottom: 64px;
    font-size: 11px;
  }

  &_download_icon {
    margin-bottom: 116px;
    img {
      width: 58px;
      height: 58px;
    }
  }
}
</style>
