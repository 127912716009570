import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtag from 'vue-gtag'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)
import VueCookies from 'vue-cookies'

const cookies = VueCookies

import ConstantsPlugin from '../plugins/constants'
Vue.use(ConstantsPlugin)

import OpenIndex from '@/pages/open/Index'
import About from '@/pages/open/About'
import Faq from '@/pages/open/Faq'
import Contact from '@/pages/open/Contact'
import TeamsOfService from '@/pages/open/TeamsOfService'
import PrivacyPolicy from '@/pages/open/PrivacyPolicy'

import Test from '@/pages/TestPage'

import Registration from '@/pages/open/Registration'
import VerificationSend from '@/pages/open/VerificationSend'
import VerificationConfirm from '@/pages/open/VerificationConfirm'
import PasswordForget from '@/pages/open/PasswordForget'
import PasswordReset from '@/pages/open/PasswordReset'
import ProfileEdit from '@/pages/open/ProfileEdit'
import ProfileConfirm from '@/pages/open/ProfileConfirm'
import Login from '@/pages/open/Login'
import JuSetting from '@/pages/open/JuSetting'

import HomeHome from '@/pages/home/Index'
import HomeSearch from '@/pages/home/Search'
import HomeMenu from '@/pages/home/Menu'
import HomeFavorite from '@/pages/home/Favorite'
import HomeNotice from '@/pages/home/Notice'
import HomeProfile from '@/pages/home/Profile'
import HomeProfileEdit from '@/pages/home/ProfileEdit'
import HomeNoticeEdit from '@/pages/home/NoticeEdit'
import HomeTeam from '@/pages/home/WebView'
import OpenDeleteAccount from '@/pages/open/DeleteAccount'

import UnionHome from '@/pages/union/Index'
import UnionCourseQuiz from '@/pages/union/CourseQuiz'
import UnionCourseStaticQuiz1 from '@/pages/union/CourseStaticQuiz1'
import UnionCourseStaticQuiz2 from '@/pages/union/CourseStaticQuiz2'
import UnionCourseQuizResult from '@/pages/union/CourseQuizResult'
import TeamHome from '@/pages/team/Index'
import TeamInfo from '@/pages/team/Info'
import TeamPostDetail from '@/pages/team/PostDetail'
import TeamPostCreate from '@/pages/team/PostCreate'
import TeamMiniVoteDetail from '@/pages/team/MiniVoteDetail'
import TeamMiniVoteResult from '@/pages/team/MiniVoteResult'
import Comment from '@/pages/team/Comment'
import TeamJoin from '@/pages/team/Join'
import TeamRuleSetting from '@/pages/team/RuleSetting'
import MiniVoteCreate from '@/pages/team/MiniVoteCreate'
import TeamManageMember from '@/pages/team/ManageMember'
import TeamCreate from '@/pages/team/Create'
import PostNoticeEdit from '@/pages/team/PostNoticeEdit'
import TeamMemberProfile from '@/pages/team/MemberProfile'

import UnionSetting from '@/pages/union/Setting'
import UnionCorner from '@/pages/union/Corner'
import UnionCourse from '@/pages/union/Course'
import UnionLeaningStatus from '@/pages/union/LeaningStatus'
import UnionGift from '@/pages/union/Gift'
import UnionGiftDetail from '@/pages/union/GiftDetail'
import UnionGiftTarget from '@/pages/union/GiftTarget'
import UnionGiftSetting from '@/pages/union/GiftSetting'

import HomeNoticeText from '@/pages/home/NoticeText'
import ErrorBase from '@/pages/errors/Error'
import Maintenance from '@/pages/Maintenance'
import ExternalSystemLogin from '@/pages/ExternalSystemLogin'
import HomeChangeAuth from '@/pages/home/ChangeAuth'
import HomeChangeAuthRequested from '@/pages/home/ChangeAuthRequested'
import HomeChangeAuthConfirm from '@/pages/home/ChangeAuthConfirm'
import HomeGroupManagement from '@/pages/home/GroupManagement'
import HomeUnionTeamList from '@/pages/home/UnionTeamList'

// ローカルではGoogleアナリティクスのセットアップをしない
if (['develop', 'staging', 'production'].includes(process.env.VUE_APP_NODE_ENV)) {
  // Googleアナリティクス設定
  Vue.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GA_ID,
      params: {
        // 自動集計フラグ
        send_page_view: false
      }
    }
  })
}  else {
  // ローカル環境用のスタブ
  Vue.prototype.$gtag = {
    pageview: () => {},
    event: () => {}
  }
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: OpenIndex,
    meta: {
      layout: 'open-top',
      auth: false,
      title: '',
    },
  },
  {
    path: '/open/index',
    name: 'OpenIndex',
    component: OpenIndex,
    meta: {
      layout: 'open-top',
      title: '',
    },
  },
  {
    path: '/open/about',
    name: 'About',
    component: About,
    meta: {
      layout: 'open-information',
      title: '13000_運営会社について',
    },
  },
  {
    path: '/open/faq',
    name: 'Faq',
    component: Faq,
    meta: {
      layout: 'open-information',
      title: '14000_よくある質問',
    },
  },
  {
    path: '/open/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      layout: 'open-information',
      title: '15000_お問い合わせ',
    },
  },
  {
    path: '/open/teams_of_service',
    name: 'TeamsOfService',
    component: TeamsOfService,
    meta: {
      layout: 'open-information',
      title: '16000_利用規約',
    },
  },
  {
    path: '/open/privacy_policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      layout: 'open-information',
      title: '17000_個人情報保護方針',
    },
  },
  {
    path: '/open/login',
    name: 'Login',
    component: Login,
    meta: {
      auth: false,
      layout: 'open',
      title: '11000_ログイン',
    },
  },
  {
    path: '/open/ju_setting',
    name: 'JuSetting',
    component: JuSetting,
    meta: {
      auth: false,
      layout: 'open',
      title: '11400_ログイン2',
    },
  },
  {
    path: '/open/ju_setting/act_login',
    name: 'ActLogin',
    component: JuSetting,
    props: () => ({
      is_act_login: true,
    }),
    meta: {
      auth: false,
      layout: 'open',
      title: '',
    },
  },
  {
    path: '/open/registration',
    name: 'Registration',
    component: Registration,
    meta: {
      auth: false,
      layout: 'open',
      title: '11100_新規会員登録',
    },
  },
  {
    path: '/open/verification_send/:mode',
    name: 'VerificationSend',
    component: VerificationSend,
    props: (route) => ({
      mode: String(route.params.mode),
    }),
    meta: {
      auth: false,
      layout: 'open',
      title: '11810_本人確認メッセージ送信完了',
    },
  },
  {
    path: '/open/verification_confirm',
    name: 'VerificationConfirm',
    component: VerificationConfirm,
    meta: {
      auth: false,
      layout: 'open',
      title: '11300_確認コード入力',
    },
  },
  {
    path: '/open/password_forget',
    name: 'PasswordForget',
    component: PasswordForget,
    meta: {
      auth: false,
      layout: 'open',
      title: '11800_パスワード忘れ',
    },
  },
  {
    path: '/open/password_reset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {
      auth: false,
      layout: 'open',
      title: '11830_パスワード再設定',
    },
  },
  {
    path: '/open/profile_edit',
    name: 'ProfileEdit',
    component: ProfileEdit,
    meta: {
      auth: true,
      layout: 'open',
      title: '11500_プロフィール登録',
    },
  },
  {
    path: '/open/profile_confirm',
    name: 'ProfileConfirm',
    component: ProfileConfirm,
    meta: {
      auth: true,
      layout: 'open',
      title: '11600_プロフィール登録確認',
    },
  },
  {
    path: '/home/home',
    name: 'HomeHome',
    component: HomeHome,
    meta: {
      auth: true,
      layout: 'TopHome',
      title: '20000_HOME',
    },
  },
  {
    path: '/team/home/:team_id(\\d+)',
    name: 'TeamHome',
    component: TeamHome,
    props: (route) => ({
      team_id: Number(route.params.team_id),
    }),
    meta: {
      auth: true,
      screen_auth_team: true,
      layout: 'team-without-header',
      title: '30000_チームHOME',
    },
  },
  {
    path: '/team/info/:team_id(\\d+)',
    name: 'TeamInfo',
    component: TeamInfo,
    props: (route) => ({
      team_id: Number(route.params.team_id),
    }),
    meta: {
      auth: true,
      layout: 'team',
      title: '31100_チーム情報',
    },
  },
  {
    path: '/home/search',
    name: 'HomeSearch',
    component: HomeSearch,
    meta: {
      auth: true,
      layout: 'home',
      title: '21000_検索',
    },
  },
  {
    path: '/team/post-detail/:team_id(\\d+)/:chat_id(\\d+)/:type?',
    name: 'TeamPostDetail',
    component: TeamPostDetail,
    props: (route) => ({
      team_id: Number(route.params.team_id),
      chat_id: Number(route.params.chat_id),
      type: String,
    }),
    meta: {
      auth: true,
      layout: 'team-new',
      title: '32100_投稿閲覧',
    },
  },
  {
    path: '/team/comment/:team_id(\\d+)/:chat_id(\\d+)/:type(\\d+)/:comment_id(\\d+)?/:comment?',
    name: 'Comment',
    component: Comment,
    props: (route) => ({
      team_id: Number(route.params.team_id),
      chat_id: Number(route.params.chat_id),
      type: Number(route.params.type),
      comment_id: Number(route.params.comment_id),
      comment: String,
    }),
    meta: {
      auth: true,
      layout: 'comment',
      title: '32110_コメント投稿',
    },
  },
  {
    path: '/team/post-create/:team_id/:chat_id?',
    name: 'TeamPostCreate',
    component: TeamPostCreate,
    props: (route) => ({
      team_id: Number(route.params.team_id),
      chat_id: Number(route.params.chat_id),
    }),
    meta: {
      auth: true,
      layout: 'PostAndVoteCreate',
      screen_auth_chat_registration: true,
      title: '32200_新規投稿',
    },
  },
  {
    path: '/team/mini-vote-detail/:team_id(\\d+)/:chat_id(\\d+)/:type?',
    name: 'TeamMiniVoteDetail',
    component: TeamMiniVoteDetail,
    props: (route) => ({
      team_id: Number(route.params.team_id),
      chat_id: Number(route.params.chat_id),
      type: String,
    }),
    meta: {
      auth: true,
      layout: 'team-new',
      title: '33100_投票閲覧・投票',
    },
  },
  {
    path: '/team/mini-vote-result/:team_id(\\d+)/:chat_id(\\d+)',
    name: 'TeamMiniVoteResult',
    component: TeamMiniVoteResult,
    props: (route) => ({
      team_id: Number(route.params.team_id),
      chat_id: Number(route.params.chat_id),
    }),
    meta: {
      auth: true,
      layout: 'vote-result',
      title: '33300_結果を見る',
    },
  },
  {
    path: '/team/mini-vote-create/:team_id/:chat_id?',
    name: 'MiniVoteCreate',
    component: MiniVoteCreate,
    props: (route) => ({
      team_id: Number(route.params.team_id),
      chat_id: Number(route.params.chat_id),
    }),
    meta: {
      auth: true,
      layout: 'PostAndVoteCreate',
      screen_auth_mini_vote_registration: true,
      title: '33200_新規ミニ投票',
    },
  },
  {
    path: '/team/join/web',
    name: 'TeamJoinWeb',
    component: TeamJoin,
    props: () => ({
      mode: 'vue',
    }),
    meta: {
      auth: true,
      layout: 'open',
      title: '',
    },
  },
  {
    path: '/team/join/app',
    name: 'TeamJoinApp',
    component: TeamJoin,
    props: () => ({
      mode: 'app',
    }),
    meta: {
      auth: true,
      layout: 'open',
      title: '31201_JOIN',
    },
  },
  {
    path: '/team/rule-setting',
    name: 'TeamRuleSetting',
    component: TeamRuleSetting,
    meta: {
      layout: 'team',
      title: '',
    },
  },
  {
    path: '/team/post-notice-edit/:team_id(\\d+)?',
    name: 'PostNoticeEdit',
    component: PostNoticeEdit,
    props: (route) => ({
      team_id: Number(route.params.team_id),
    }),
    meta: {
      auth: true,
      layout: 'team',
      title: '',
    },
  },
  {
    path: '/home/favorite',
    name: 'HomeFavorite',
    component: HomeFavorite,
    meta: {
      auth: true,
      layout: 'home',
      title: '23200_お気に入り',
    },
  },
  {
    path: '/home/menu',
    name: 'HomeMenu',
    component: HomeMenu,
    meta: {
      layout: 'home',
      title: '24000_メニュー',
    },
  },
  {
    path: '/home/notice',
    name: 'HomeNotice',
    component: HomeNotice,
    meta: {
      auth: true,
      layout: 'home',
      title: '22300_チームお知らせ',
    },
  },
  {
    path: '/home/profile/:user_id(\\d+)?',
    name: 'HomeProfile',
    component: HomeProfile,
    props: (route) => ({
      user_id: Number(route.params.user_id),
    }),
    meta: {
      auth: true,
      layout: 'home',
      title: '24100_プロフィール',
    },
  },
  {
    path: '/home/profile_edit',
    name: 'HomeProfileEdit',
    component: HomeProfileEdit,
    meta: {
      auth: true,
      layout: 'home',
      title: '24110_プロフィール編集',
    },
  },
  {
    path: '/home/notice_edit',
    name: 'HomeNoticeEdit',
    component: HomeNoticeEdit,
    meta: {
      auth: true,
      layout: 'home',
      title: '24200_通知設定',
    },
  },
  {
    path: '/home/team/:team_id',
    name: 'HomeTeam',
    component: HomeTeam,
    meta: {
      auth: true,
      layout: 'home',
      title: '',
    },
  },
  {
    path: '/union/home',
    name: 'UnionHome',
    component: UnionHome,
    meta: {
      auth: true,
      layout: 'union',
      title: '40000_UNION SALON(Radar)',
    },
  },
  {
    path: '/union/course_quiz/:lecture_id(\\d+)/:corner_id(\\d+)?',
    name: 'UnionCourseQuiz',
    component: UnionCourseQuiz,
    props: (route) => ({
      corner_id: Number(route.params.corner_id),
      lecture_id: Number(route.params.lecture_id),
    }),
    meta: {
      auth: true,
      layout: 'union',
      title: '43200_視聴確認クイズ',
    },
  },
  {
    path: '/union/course_static_quiz_1/:lecture_id(\\d+)/:corner_id(\\d+)?',
    name: 'UnionCourseStaticQuiz1',
    component: UnionCourseStaticQuiz1,
    props: (route) => ({
      corner_id: Number(route.params.corner_id),
      lecture_id: Number(route.params.lecture_id),
    }),
    meta: {
      auth: true,
      layout: 'union',
      title: '43211_視聴確認クイズ(固定設問)',
    },
  },
  {
    path: '/union/course_static_quiz_2/:lecture_id(\\d+)/:corner_id(\\d+)?',
    name: 'UnionCourseStaticQuiz2',
    component: UnionCourseStaticQuiz2,
    props: (route) => ({
      corner_id: Number(route.params.corner_id),
      lecture_id: Number(route.params.lecture_id),
    }),
    meta: {
      auth: true,
      layout: 'union',
      title: '43212_視聴確認クイズ(固定設問)-1',
    },
  },
  {
    path: '/union/course_quiz_result/:corner_id(\\d+)?',
    name: 'UnionCourseQuizResult',
    component: UnionCourseQuizResult,
    props: (route) => ({
      corner_id: Number(route.params.corner_id),
    }),
    meta: {
      auth: true,
      layout: 'union',
      title: '43110_視聴確認クイズ(完了)',
    },
  },
  {
    path: '/team/:team_id(\\d+)/manage-member',
    name: 'TeamManageMember',
    component: TeamManageMember,
    props: (route) => ({
      team_id: Number(route.params.team_id),
    }),
    meta: {
      auth: true,
      screen_auth_team: true,
      layout: 'team',
      title: '31240_チームメンバー管理',
    },
  },
  {
    path: '/team/:team_id(\\d+)/member-profile/:user_id(\\d+)',
    name: 'TeamManageProfile',
    component: TeamMemberProfile,
    props: (route) => ({
      team_id: Number(route.params.team_id),
      user_id: Number(route.params.user_id),
    }),
    meta: {
      auth: true,
      layout: 'team',
      title: '31111_メンバープロフィール',
    },
  },
  {
    path: '/team/create/:team_id(\\d+)?',
    name: 'TeamCreate',
    component: TeamCreate,
    props: (route) => ({
      team_id: Number(route.params.team_id),
    }),
    meta: {
      auth: true,
      layout: 'team',
      title: '31230_チームの設定を編集',
    },
  },
  {
    path: '/union/setting/:team_id(\\d+)',
    name: 'UnionSetting',
    component: UnionSetting,
    props: (route) => ({
      team_id: Number(route.params.team_id),
    }),
    meta: {
      auth: true,
      layout: 'union',
      title: '45000_組織内ユーザー追加画面一覧',
    },
  },
  {
    path: '/union/corner/:corner_id(\\d+)',
    name: 'UnionCorner',
    component: UnionCorner,
    props: (route) => ({
      corner_id: Number(route.params.corner_id),
    }),
    meta: {
      auth: true,
      layout: 'union',
      title: '42000_コーナーTOP',
    },
  },
  {
    path: '/union/course/:lecture_id(\\d+)',
    name: 'UnionCourse',
    component: UnionCourse,
    props: (route) => ({
      lecture_id: Number(route.params.lecture_id),
    }),
    meta: {
      auth: true,
      layout: 'union',
      title: '43000_講座',
    },
  },
  {
    path: '/union/gift/:team_id(\\d+)',
    name: 'UnionGift',
    component: UnionGift,
    props: (route) => ({
      team_id: Number(route.params.team_id),
    }),
    meta: {
      auth: true,
      layout: 'union',
      title: '48001_メッセージ送信',
    },
  },
  {
    path: '/union/gift_detail/:team_id(\\d+)/:gift_id(\\d+)',
    name: 'UnionGiftDetail',
    component: UnionGiftDetail,
    props: (route) => ({
      team_id: Number(route.params.team_id),
      gift_id: Number(route.params.gift_id),
    }),
    meta: {
      auth: true,
      layout: 'union',
      title: '48100_メッセージ詳細',
    },
  },
  {
    // path: '/union/gift_target/:team_id(\\d+)/:gift_target_id(\\d+)?',
    path: '/union/gift_target/:team_id(\\d+)',
    name: 'UnionGiftTarget',
    props: (route) => ({
      team_id: Number(route.params.team_id),
      // gift_target_id: route.params.gift_target_id ? Number(route.params.gift_target_id) : null,
    }),
    component: UnionGiftTarget,
    meta: {
      auth: true,
      layout: 'union',
      title: '48300_配布可能なメッセージの詳細設定',
    },
  },
  {
    path: '/home/noticetext/:news_id(\\d+)',
    name: 'HomeNoticeText',
    component: HomeNoticeText,
    props: (route) => ({
      news_id: Number(route.params.news_id),
    }),
    meta: {
      auth: true,
      layout: 'home',
      title: '32310_お知らせ本文',
    },
  },
  {
    path: '/home/change_auth',
    name: 'HomeChangeAuth',
    component: HomeChangeAuth,
    meta: {
      auth: true,
      layout: 'home',
      title: '',
    },
  },
  {
    path: '/home/change_auth/requested',
    name: 'HomeChangeAuthRequested',
    component: HomeChangeAuthRequested,
    meta: {
      auth: true,
      layout: 'home',
      title: '',
    },
  },
  {
    path: '/home/change_auth/confirm',
    name: 'HomeChangeAuthConfirm',
    component: HomeChangeAuthConfirm,
    meta: {
      auth: true,
      layout: 'home',
      title: '',
    },
  },
  {
    path: '/home/group_management',
    name: 'HomeGroupManagement',
    component: HomeGroupManagement,
    meta: {
      auth: true,
      layout: 'union',
      title: '',
    },
  },
  {
    path: '/home/group_management/:union_code',
    name: 'HomeUnionTeamList',
    component: HomeUnionTeamList,
    meta: {
      auth: true,
      layout: 'union',
      title: '',
    },
  },
  {
    path: '/union/gift_setting/:team_id(\\d+)/:setting_id(\\d+)?',
    name: 'UnionGiftSetting',
    component: UnionGiftSetting,
    props: (route) => ({
      team_id: Number(route.params.team_id),
    }),
    meta: {
      auth: true,
      layout: 'union',
      title: '48220_メッセージ送信設定',
    },
  },
  {
    path: '/union/leaning_status',
    name: 'UnionLeaningStatus',
    component: UnionLeaningStatus,
    meta: {
      auth: true,
      layout: 'union',
      title: '44000_学習状況',
    },
  },
  {
    path: '/open/delete_account',
    name: 'OpenDeleteAccount',
    component: OpenDeleteAccount,
    meta: {
      auth: true,
      layout: 'open-information',
      title: '14100_退会フォーム',
    },
  },
  {
    path: '/test/',
    name: 'Test',
    component: Test,
  },
  {
    path: '/error/:status_code(\\d+)',
    name: 'Error',
    component: ErrorBase,
    props: (route) => ({
      status_code: Number(route.params.status_code),
    }),
    meta: {
      layout: 'open-top',
      auth: false,
      title: '',
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: {
      layout: null,
      auth: false,
      title: '',
    },
  },
  {
    path: '*',
    redirect: 'error/404',
    name: 'NotFound',
  },
  {
    path: '/:account_id/sso',
    name: 'ExternalSystemLogin',
    component: ExternalSystemLogin,
    props: (route) => ({
      account_id: String(route.params.account_id),
      uuid: String(route.query.uuid),
      sp: String(route.query.sp),
    }),
    meta: {
      layout: null,
      auth: false,
      title: '',
    },
  },
]

const router = new VueRouter({
  base: process.env.VUE_APP_PUBLIC_PATH,
  mode: 'history',
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

async function updateToken(auth_info) {
  let new_auth_info = {
    tel_or_email: auth_info.tel_or_email,
    refresh_token: auth_info.refresh_token,
  }

  try {
    const response = await $axios.post(
      '/api/' + Vue.prototype.$constants.API_VERSION + '/auth/token/refresh',
      {
        tel_or_email: auth_info.tel_or_email,
        refresh_token: auth_info.refresh_token,
      }
    )
    const value = response.data.value

    new_auth_info['access_token'] = value.access_token
    new_auth_info['expired_at'] = dayjs().add(value.expires_in, 's').format()
    return new_auth_info
  } catch {
    //エラーだったら認証情報を空にして返す
    return {}
  }
}

async function screen_auth_team(team_id) {
  let code = 200

  await $axios
    .post(
      '/api/' + Vue.prototype.$constants.API_VERSION + '/screen_auth/team',
      {
        team_id: team_id,
      }
    )
    .catch((err) => {
      if (err.response.status === 460) {
        code = 460
      }
    })

  return code
}

async function screen_auth_chat_registration(type, team_id, chat_id) {
  let code = 200

  if (chat_id !== undefined && 0 < chat_id.length) {
    await $axios
      .post(
        '/api/' +
          Vue.prototype.$constants.API_VERSION +
          '/screen_auth/chat/update',
        {
          chat_id: chat_id,
        }
      )
      .catch((err) => {
        if (err.response.status === 460) {
          code = 460
        }
      })
  } else {
    await $axios
      .post(
        '/api/' +
          Vue.prototype.$constants.API_VERSION +
          '/screen_auth/chat/registration',
        {
          team_id: team_id,
          type: type,
        }
      )
      .catch((err) => {
        if (err.response.status === 460) {
          code = 460
        }
      })
  }

  return code
}

// ログインの振り分け
router.beforeEach(async function (to, from, next) {
  const isMaintenance = false
  const allowIp = [
    '210.227.234.114',
    '122.219.190.186',
    '114.156.79.8',
    '134.238.4.84',
    '134.238.4.83',
    '114.141.120.50',
    '114.141.120.185',
    '219.107.176.124',
    '113.156.128.101',
    '157.107.64.140'
  ]
  if (isMaintenance && to.name != 'Maintenance') {
    let isAllow = false
    await fetch('https://api.ipify.org/?format=json')
    .then(res => res.json())
    .then(json => {
      console.log(json)
      let accessIp = json.ip
      if (allowIp.includes(accessIp)) {
        isAllow = true
      }
    })
    .catch(err => console.log(err))

    if (!isAllow) {
      next({ name: 'Maintenance'})
    }
  }
  if (to.matched.some((record) => record.meta.auth)) {
    // ログインしているか判定
    const auth_info = cookies.get('auth_info')

    if (Object.keys(auth_info ?? {}).length > 0) {
      // アクセストークンが期限切れの場合はリフレッシュトークンを用いて再発行
      if (!dayjs().isBefore(auth_info.expired_at)) {
        const new_auth_info = await updateToken(auth_info)

        if (Object.keys(new_auth_info).length > 0) {
          cookies.set('auth_info', new_auth_info)
        } else {
          //アクセストークンの更新ができていなかった場合、ログインからやり直し
          next({ path: '/open/login', query: { redirect: to.fullPath } })
        }
      }
    } else {
      // ログインしていなければログイン後リダイレクトで戻れるようにクエリ持たせてログイン画面に遷移
      next({ path: '/open/login', query: { redirect: to.fullPath } })
    }
  }

  // ユーザーに組織のページ閲覧権限があるかどうかをチェック
  if (to.matched.some((record) => record.meta.screen_auth_team)) {
    const screen_auth_team_status = await screen_auth_team(to.params.team_id)
    if (screen_auth_team_status === 460) {
      // 460が返却された場合は404エラーを見せる
      next({ path: `/error/404` })
    }
  }

  // 新規投稿権限があるかチェック
  if (to.matched.some((record) => record.meta.screen_auth_chat_registration)) {
    const status_code = await screen_auth_chat_registration(
      1,
      to.params.team_id,
      to.params.chat_id
    )
    if (status_code === 460) {
      // 460が返却された場合は404エラー画面へ遷移
      next({ path: `/error/404` })
    }
  }

  // 新規ミニ投票権限があるかチェック
  if (
    to.matched.some((record) => record.meta.screen_auth_mini_vote_registration)
  ) {
    const status_code = await screen_auth_chat_registration(
      2,
      to.params.team_id,
      to.params.chat_id
    )
    if (status_code === 460) {
      // 460が返却された場合は404エラー画面へ遷移
      next({ path: `/error/404` })
    }
  }

  // くみあいアプリトップページ表示時の処理
  if (to.name == 'Index') {
    // ログインしている場合は、Home画面に遷移させる
    const auth_info = cookies.get('auth_info')
    if (auth_info) {
      next({ name: 'HomeHome' })
    }
  }

  //全通過で正常に次ページを開く
  next()
})

/**
 * グローバルアフターフック
 * 画面遷移後に実行される
 * @param {Route} to - 次にナビゲーションされる対象のルートオブジェクト
 */
router.afterEach((to) => {
  try {
    var path = to.path
    var screenName = to.meta.title
    // 本人確認メッセージ送信完了のモードを判定
    if (path.indexOf('verification_send') !== -1) {
      var splitPath = path.split('/')
      // 本人確認メッセージ送信完了のURL決め打ちなので要素数を4で決め打ち
      if (splitPath?.length === 4) {
        // 可変パラメータのモードを取得
        const mode = splitPath?.[3]
        // 新規か再設定か判定
        const isNew = mode === 'new'
        if (isNew) {
          screenName = '11200_本人確認メッセージ送信完了(新規)'
        } else {
          screenName = '11810_本人確認メッセージ送信完了(再設定)'
        }
      }
    }
    // 手動でページビュー更新
    if (Vue.prototype.$gtag && typeof Vue.prototype.$gtag.pageview === 'function') {
      Vue.prototype.$gtag.pageview({
        page_path: path,
        page_title: screenName,
      })
    } else {
      console.warn('Vue.$gtag is not initialized or pageview function is not available')
    }
  } catch(error) {
    console.error('Error in afterEach guard:', error)
  }
})

export default router
