<template>
  <table :class="$style.table" height="1">
    <thead>
      <tr>
        <th v-if="selectable" :class="$style.select" width="1">
          <base-check-box v-model="boundSelectedAll" />
        </th>
        <th
          v-for="(colDef, col) in colDefs"
          :key="colDef.key || `__unnamed_col_${col}`"
          :class="{
            [$style.sortable]: isSortableCol(colDef),
            [$style.sorted]: isSortedCol(colDef)
          }"
          @click="() => handleClickTh(col)"
        >
          <slot
            :name="colDef.label_slot ||`__unnamed_label_slot_${col}`"
            v-bind="{ col, key: colDef.key, colDef }"
          >
            <span>{{ colDef.label }}</span>
          </slot>

          <base-icon
            v-if="colDef.sortable"
            type="fas"
            :name="colDef.key == sortedKey ? sortedAsc ? 'sort-up' : 'sort-down' : 'sort'"
            size="11px"
            :class="$style.icon"
          />
        </th>
      </tr>
    </thead>
    <tbody :class="{
        [$style[`bg_${this.bgColor}`]]: !!this.bgColor,
      }">
      <tr v-if="rowDataEmpty">
        <td :colspan="colDefsNum + 1">{{ message }}</td>
      </tr>
      <tr
        v-for="(rowDat, row) in pageSortedRowData"
        :key="getRowKeyValue(rowDat)"
        :class="{ [$style.selected]: isSelectedRow(rowDat) }"
      >
        <td v-if="selectable" :class="$style.select">
          <base-check-box :value="getRowKeyValue(rowDat)" v-model="boundSelected" />
        </td>
        <td
          v-for="(colDef, col) in colDefs"
          :key="colDef.key || `__unnamed_col_${col}`"
          :class="{
            [$style.highlight]: colDef.highlightCondition && colDef.highlightCondition(rowDat),
            [$style.sorted]: isSortedCol(colDef),
            [$style.align_left]: getTextAlignCol(colDef) == 'left',
            [$style.align_center]: getTextAlignCol(colDef) == 'center',
            [$style.align_right]: getTextAlignCol(colDef) == 'right',
            }"
          @click="() => {handleClickTd(row, col, colDef, rowDat)}"
        >
          <slot
            :name="colDef.slot ||`__unnamed_slot_${col}`"
            v-bind="{
              row, col,
              key: colDef.key,
              value: rowDat[colDef.key],
              colDef, rowDat,
              [colDef.key]: rowDat[colDef.key]
            }"
          >
            {{ formatTd(rowDat[colDef.key], colDef) }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import BaseCheckBox from "../../base/BaseCheckBox/BaseCheckBox"
import BaseIcon from "../../base/BaseIcon/BaseIcon"
export default {
  name: "TableList",
  components: { BaseCheckBox, BaseIcon },
  props: {
    /**
     * 列定義配列
     * {
     *    // データキー名（列間で一意な名前であること）。
     *    // スロットを使わない場合は、行データの同名の値がこの列に並ぶ。
     *    // スロットを使う場合でも、ソート可能ならソート列の選択名として使うので必要。（行データに同名の値を持っている必要はない。）
     *    key?: string
     *    label?: string // ラベル名。最初の行に列名として表示される。
     *    sortable?: boolean // ソート可能な列ならtrue
     *    comparer?: (rowDatA, rowDatB) => number // 値が比較可能でないなら指定する
     *    // 列のv-slot名。
     *    // スロットテンプレートは以下のスロットプロパティを受け取る。
     *    // { row, col, key, value, colDef, rowDat, [key]: value }
     *    // スロットプロパティは以下のようにしてスロットテンプレートで受け取り、表示に利用できる。
     *    // <template v-slot:test1_slot="{ row, col, value }">({{ row }}, {{ col }}) = {{ value }}</template>
     *    // <template v-slot:test2_slot="cellData">({{ cellData.row }}, {{ cellData.col }}) = {{ cellData.value }}</template>
     *    slot?: string
     *    // 列ラベルのv-slot名。
     *    // 列ラベルの位置に文字列以外を表示するならlabelの代わりに指定する。
     *    // スロットテンプレートは以下のスロットプロパティを受け取る。
     *    // { col, key, colDef }
     *    label_slot?: string
     *
     *    // 以下、スロットプロパティやcomparerのために任意の定義を含めても良い。
     * }[]
     * */
    colDefs: {
      type: Array,
      default: () => []
    },
    /**
     * 行データ配列。
     * colDefsのkeyで定義した名前の値を持つオブジェクト配列。
     * colDefsで定義されないデータが含まれていてもよい。
     * */
    rowData:{
      type: Array,
      default: () => []
    },
    /**
     * 選択されたデータのキー値配列。
     * このデータが選択中の表示になる。キーはrowKeyで指定する。
     */
    selected: {
      type: Array,
      default: null
    },
    /**
     * データのキー。
     * データが選択された際にこのキーの値の配列が$emit('select', [...])される。
     * 省略されるとrowDataのインデックスが使用される。
     * */
    rowKey: {
      type: String,
      default: ""
    },
    /**
     * ソート列。
     * この列でソート中の表示になる。
     * noSortがfalseなら、この順序でデータもソートされて表示される。
     * */
    sortKey: {
      type: String,
      default: ""
    },
    /**
     * ソートの昇順、降順。
     * trueなら昇順、falseなら降順の表示になる。
     * noSortがfalseなら、この順序でデータもソートされて表示される。
     * */
    sortAsc: {
      type: Boolean,
      default: true
    },
    /**
     * データを実際にソートするかどうか。
     * ソート時に$emit('sort', { key, asc })のみを行うならtrue。
     * サーバでソート済みの部分データが渡される場合など。
     * */
    noSort: {
      type: Boolean,
      default: false
    },
    /**
     * ページ番号。
     * pageDataNumで指定された件数を１ページとして、表示されるページ数を指定する。
     * 管理上の開始ページ番号と一致させるためにpageStartを使うことができる。
     */
    page: {
      type: Number,
      default: 1
    },
    /**
     * １ページのデータ件数。
     * pageDataNumで指定された件数を１ページとして、一度に表示されるデータ数を指定する。
     * 全データ表示するなら省略するか（null, undefined, 0）か、データ件数より多い数を指定する。
     */
    pageDataNum: {
      type: Number,
      default: null
    },
    /**
     * ページ番号の開始位置。
     * pageが1から始まるなら1。
     */
    pageStart: {
      type: Number,
      default: 1
    },
    /**
     * データ取得モード
     * true: 都度取得(ページリンク押下時に対象データを取得するモード)
     * false: 全レコード取得(最初に対象データを取得し全データを保持するモード)[default]
     */
    fetchMode: {
      type: Boolean,
      required: false,
      default: false
    },
    bgColor: {
      type: String,
      default: "white",
      validator: (value) => ["white", "gray"].includes(value)
    },
    message: {
      type: String,
      default: "設定はありません",
    },
    selectable: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      sortedRowData: [],
      sortedKey: this.sortKey,
      sortedAsc: this.sortAsc
    }
  },
  mounted() {
      this.copyRowData()
      this.sortRowData()
  },
  computed: {
    boundSelected: {
      get() {
        return this.selected
      },
      set(value) {
        // console.log("boundSelected set:", value)
        this.$emit('select', value)
      }
    },
    boundSelectedAll: {
      get() {
        const rowData = this.pageSortedRowData // ページ内で全選択。全データならthis.sortedRowDataにする
        return rowData.every((rowDat) => this.selected.includes(this.getRowKeyValue(rowDat)))
      },
      set(value) {
        let selected
        if (value) {
          const rowData = this.pageSortedRowData // ページ内で全選択。全データならthis.sortedRowDataにする
          selected = rowData.map((rowDat) => this.getRowKeyValue(rowDat))

          // ページ内で全選択なら現在の選択に追加する。全データなら不要な処理
          selected = this.uniqueConcat(this.selected, selected)
        } else {
          // selected = [] // 全データ選択解除ならこちら

          // ページ内で全選択解除なら現在の選択から削除する。全データなら不要な処理
          const pageUnselected = this.pageSortedRowData.map((rowDat) => this.getRowKeyValue(rowDat))
          selected = this.selected.filter((sel) => !pageUnselected.includes(sel))
        }
        this.boundSelected = selected
      }
    },
    pageSortedRowData() {
      if(this.fetchMode){ // 都度取得
        return this.sortedRowData;
      }
      const start = this.pageDataNum > 0 ? (this.page - this.pageStart) * this.pageDataNum : 0
      const end = start + (this.pageDataNum > 0 ? this.pageDataNum : this.sortedRowData.length)
      return this.sortedRowData.slice(start, end)
    },
    colDefsNum() {
      return this.colDefs.length
    },
    rowDataEmpty() {
      return this.rowData.length === 0
    }
  },
  methods: {
    getRowKeyValue(rowDat) {
      const rowKey = this.rowKey || "__originalIndex"
      return rowDat[rowKey]
    },
    isSelectedRow(rowDat) {
      return this.selected.includes(this.getRowKeyValue(rowDat))
    },
    isSortableCol(colDef) {
      return colDef.sortable && colDef.key
    },
    isSortedCol(colDef) {
      return this.isSortableCol(colDef) && colDef.key == this.sortedKey
    },
    getTextAlignCol(colDef) {
      if (colDef.textAlign && colDef.key) {
        return colDef.textAlign
      } else {
        return 'left'
      }
    },

    handleClickTh(col) {
      const colDef = this.colDefs[col]
      if (colDef.sortable) { // ソート可能列
        if (this.sortedKey == colDef.key) { // 同じキーでもう一度ソートする
          this.sortedAsc = !this.sortedAsc // 順序を入れ替える
          if (!this.sortedAsc) { // 一周したら
            this.sortedKey = null // ソートを解除する
            this.sortedAsc = true // 昇順
          }
        } else { // 違うキーで新たにソートする
          this.sortedKey = colDef.key
          this.sortedAsc = false // 降順
        }
        if(!this.fetchMode){
          this.sortRowData() // この設定でソート
        }
        this.$emit("sort", { key:  this.sortedKey, asc: this.sortedAsc})
      }
    },
    handleClickTd(row, col, colDef, rowDat) {
      // console.log("handleClickTd: ", { row, col })
      if(colDef.clickable){
        this.$emit("cell-click", {row, col, colDef, rowDat})
      }
    },
    formatTd(value, colDef) {
      colDef // 何か定義済みの簡易フォーマット処理
      return value
    },
    copyRowData() {
      this.sortedRowData = this.rowData.map((rowDat, i) => ({...rowDat, __originalIndex: i}))
    },
    sortRowData() {
      if (this.noSort) { return }
      const colDef = this.sortedKey && this.colDefs.find((colDef) => colDef.key == this.sortedKey)
      const sortedKey = this.sortedKey || "__originalIndex"
      const comparer = colDef && colDef.comparer || ((rowDatA, rowDatB) => rowDatA[sortedKey] < rowDatB[sortedKey] ? -1 : +1)
      this.sortedRowData.sort(
        (rowDatA, rowDatB) => {
          const c = comparer(rowDatA, rowDatB)
          return this.sortedAsc ? c : -c;
        }
      )
      // console.log("sort: ", { key: this.sortedKey, asc: this.sortedAsc, data: this.sortedRowData })
    },
    uniqueConcat(...arrays) {
      return Array.from(new Set(arrays.flat()))
    }
  },
  watch: {
    rowData(/*rowData*/) {
      this.copyRowData()
      this.sortRowData()
    },
    sortKey(/*sortKey*/) {
      this.sortRowData()
    },
    sortAsc(/*sortAsc*/) {
      this.sortRowData()
    },
    colDefs() {
      this.sortedKey = this.sortKey
      this.sortedAsc = this.sortAsc
    }
  }
}
</script>
<style lang="scss" module>
$borderGray: #707070;
$borderLiteGray: #F0F0F0;

.table {
  border-collapse: separate;
  border-spacing: 0px;
  border-bottom: 2px solid $borderGray;

  thead {
    font-size: 11px;
    font-weight: bold;
    text-align: left;

    tr {
      &:first-child th {
        border-bottom: 2px solid $borderGray;
      }
      th {
        padding: 12px;

        &.select {
        }
        &.sortable {
          .icon {
            margin-left: 2px;
            color: $borderGray;
          }

          &.sorted {
            color: $keyPink;
            .icon {
              color: $keyPink;
            }
          }
        }
      }
    }
  }
  tbody {
    font-size: 13px;
    background-color: $keyWhite;
    vertical-align: top;

    &.bg_white {
      background-color: $keyWhite;
    }

    &.bg_gray {
      background-color: $backGroundGray;
    }

    tr {
      &:first-child td {
        border-top: 4px solid $borderLiteGray;
      }
      td {
        border-bottom: 4px solid $borderLiteGray;
      }
      // &:last-child td {
      //   border-bottom: 4px solid $borderLiteGray;
      // }

      &.selected {
        background-color: #FFFBE4;
      }

      td {
        padding: 12px;

        &.select {
        }

        &.highlight {
          background-color: #ffdddd;
          color: #d9534f;
          font-weight: bold;
        }

        &.sorted {
          font-weight: bold;
        }

        &.align_left {
          text-align: left;
        }

        &.align_center {
          text-align: center;
        }

        &.align_right {
          text-align: right;
        }
      }
    }
  }
}
</style>
