import { Radar, mixins } from 'vue-chartjs'
import Chart from "chart.js";

export default {
  extends: Radar,
  props: ["chartData", "options"],
  mixins: [mixins.reactiveProp],
  data() {
    return {
      chartOptions: {
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    update() {
      this.$data._chart.update()
    }
  },
  mounted() {
      Chart.pluginService.register({
        id: "customRadarBackground",
        beforeDraw: function (chart) {
            const ctx = chart.chart.ctx;

            // 中心の座標を取得、左のラベルの1文字分調整
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2 - 6;
            const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

            const originalRadius = Math.min(
              (chart.chartArea.right - chart.chartArea.left) / 2,
              (chart.chartArea.bottom - chart.chartArea.top) / 2
            );
            const radius = originalRadius * 0.725;

            const options = chart.options.plugins.customRadarBackground || {};
            const backgroundColor = options.color || "rgba(255, 255, 255, 1)";
            
            ctx.save();
            ctx.fillStyle = backgroundColor;
            ctx.beginPath();
    
            const labelsCount = chart.data.labels.length;
            const angleStep = (Math.PI * 2) / labelsCount;
    
            for (let i = 0; i < labelsCount; i++) {
                const angle = angleStep * i - Math.PI / 2;
                const x = centerX + radius * Math.cos(angle);
                const y = centerY + radius * Math.sin(angle);
                if (i === 0) {
                    ctx.moveTo(x, y);
                } else {
                    ctx.lineTo(x, y);
                }
            }
    
            ctx.closePath();
            ctx.fill();
            ctx.restore();
        },
    });

    this.renderChart(this.chartData, this.options)
  }
}
